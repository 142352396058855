import apis, { Apis } from './apis';
import axios from 'axios';
import http from 'react-axios-http';
import { getHeader } from 'utils';
import { envVars } from 'utils/envVars';
import { interceptor, handerError, debMsg } from './interceptor';

interface IOProps extends Apis {
  interceptors: any;
}

const adapter = config => {
  return axios({
    ...config,
    baseURL: envVars.portageurBaseUrl,
    headers: getHeader(),
  }).catch(handerError);
};

const IO: IOProps = http(apis, {
  adapter,
} as any);

IO.interceptors.response.use(res => interceptor(res).then(res => res.data),
  error => {
    debMsg(error?.message || JSON.stringify(error));
    return Promise.reject(error);
  },
);

export default IO;
