import { useMemo } from 'react';
import { useAsync } from './useAsync';
import { useDebounceEffect } from 'ahooks';

export function useSearchService<T>({
  query,
  initialData,
  fetcher,
}: {
  query: string | null;
  initialData: T;
  fetcher: (query: string) => Promise<T>;
}) {
  const { error, status, data, setData, runAsync } = useAsync<T>();

  const searchCache = useMemo(() => {
    return new Map<string | null, T | 'fetching'>();
  }, []);

  useDebounceEffect(
    () => {
      const cachedResults = searchCache.get(query);

      if (query == null) {
        setData(initialData);
        return;
      }

      if (cachedResults === 'fetching') {
        return;
      } else if (cachedResults !== undefined) {
        setData(cachedResults);
        return;
      }

      searchCache.set(query, 'fetching');
      runAsync(fetcher(query)).then(res => {
        if (res) {
          searchCache.set(query, res);
        }
      });
    },
    [ query ],
    {
      wait: 200,
      leading: true,
      trailing: true,
    },
  );

  return { error, status, data };
}
