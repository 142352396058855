import React from 'react';
import styles from './index.module.css';
import IconFont from 'components/IconFont';
import { Button, Modal } from 'antd';
import { parse } from 'query-string';

export const SubscriptionInfoModal = ({ isOpen, onClose }) => {
  const query = parse(location.search);

  const { plan, type, price, date } = query;
  const newDate = new Date(date * 1000);
  const formattedDate = newDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const getUpper = word => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        onClose();
      }}
      width={569}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      bodyStyle={{ padding: '60px 64px 48px 64px' }}
    >
      <div className={styles.container}>
        <IconFont type="icon-circle-green" style={{ fontSize: 80 }} />
        <div className={styles.title}>Subscription Confirmed!</div>
        <div className={styles.priceContainer}>
          <div className={styles.subContainer}>
            <div className={styles.strong}>{getUpper(plan)}</div>
            <div className={styles.liner}></div>
            {/* <div className={styles.strong}>US${price}</div>*/}
            {/* <div className={styles.sub}>/month</div>*/}
          </div>
          <div className={styles.info}>
            {getUpper(type)} Plan, Billed
            Monthly
          </div>
          <div className={styles.settingInfo}>
            Auto-renews on {formattedDate}. Manage in{' '}
            <a
              href="/settings/management"
              style={{ textDecoration: 'underline', color: '#424854' }}
            >
              Settings
            </a>
            .
          </div>
        </div>
        {type === 'Yearly' && (
          <div className={styles.note}>
            <span style={{ color: '#F79329' }}>Important Note:</span>
            <br />
            This is an annual subscription billed in convenient monthly
            instalments for year-long access.
          </div>
        )}
        <Button type="primary" className={styles.btn} onClick={onClose}>
          {' '}
          Get Started
        </Button>
      </div>
    </Modal>
  );
};
