import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { importImg } from 'utils';
import styles from './index.module.css';
import background from 'assets/images/bg.png';

interface IndexProps {
  msg?: string;
}

const Index: React.FC<IndexProps> = ({ msg }) => {
  const { logout } = useAuth0();

  const verifyEmail = msg === 'Please verify your email before logging in.';
  const title = verifyEmail ? 'Almost There! \n Please Check Your Email' : msg;
  const stateStatus = title === 'Invalid state';

  useEffect(() => {
    if (stateStatus) {
      setTimeout(() => {
        localStorage.clear();
        logout();
      }, 2000);
    }
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: stateStatus ? 'none' : `url(${background})`,
      }}>
      {stateStatus ? (
        <>
          <img src={importImg('favicon')} style={{ width: 70 }} />
          <h3>Let's try again!</h3>
          <h4>
            Looks like there's a temporary issue with your log in. We’re
            redirecting …
          </h4>
          <Button
            className={styles.button}
            onClick={() => {
              localStorage.clear();
              logout();
            }}>
            {' '}
            Retry log In
          </Button>
        </>
      ) : (
        <>
          <div className={styles.logo}>
            <img src={importImg('pb-logo')} />
          </div>
          <div className={styles.body}>
            <img src={importImg('pb-logo')} className={styles.bodyLogo} />
            <div className={styles.title}>{title}</div>
            {verifyEmail && (
              <div className={styles.subtitle}>
                We've sent a verification link to your email.
                <br /> Please click the link in the email to verify your
                account.
              </div>
            )}

            {/* <Button*/}
            {/*  className={styles.btn}*/}
            {/*  onClick={() => {*/}
            {/*    localStorage.clear();*/}
            {/*    logout();*/}
            {/*    // loginWithRedirect();*/}
            {/*  }}*/}
            {/* >*/}
            {/*  Sign in*/}
            {/* </Button>*/}
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
