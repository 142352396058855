import Management from './routes/Management';
import Cancel from './routes/Cancel';
import Renew from './routes/Renew';
import Tips from './routes/Tips';
import { type RouteObject, Navigate } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="management" />,
  },
  {
    path: 'management',
    element: <Management />,
  },
  {
    path: 'cancel',
    element: <Cancel />,
  },
  {
    path: 'renew',
    element: <Renew />,
  },
  {
    path: 'tips',
    element: <Tips />,
  },
  { path: '*', element: <Navigate to={'.'} /> },
];

export default routes;
