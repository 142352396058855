export const FEEDBACK_OPTIONS = [
  {
    label: 'It’s too expensive',
    value: 'too_expensive',
    show: true,
  },
  {
    label: 'I don’t use the service enough',
    value: 'unused',
    show: true,
  },
  {
    label: 'I’m switching to a different service',
    value: 'switched_service',
    show: true,
  },
  {
    label: 'Other reason',
    value: 'other',
    show: true,
  },
  {
    label: 'Customer service was less than expected',
    value: 'customer_service',
    show: false,
  },
  {
    label: 'Quality was less than expected',
    value: 'low_quality',
    show: false,
  },
  {
    label: 'Some features are missing',
    value: 'missing_features',
    show: false,
  },
  {
    label: 'Ease of use was less than expected',
    value: 'too_complex',
    show: false,
  },
];
