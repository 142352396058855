import { fetchEventSource, EventStreamContentType } from '@microsoft/fetch-event-source';
import { stringify } from 'query-string';
import { envVars } from 'utils/envVars';

interface Streaming {
  params: {
    requestId: number;
    sessionId: string;
    conversationId: string;
  };
  onMessage: (message: string) => void;
  onError: (error: string, msgType: string) => void;
  onOpen: () => void;
  onClose: (msg?: string) => void;
  isInvestment?: boolean;
}

const MSG_MAP = {
  ERR_NETWORK: 'Network error. Try again',
  ERR_SERVICE: 'Something went wrong. Please try again.',
};

const fetchSSE_2 = async ({
  authorization,
  url,
  onMessage,
  onError,
  onOpen,
  onClose,
}) => {
  console.log('======>fetchSSE<====== ');

  const customFetch = async (url, options) => {
    return await fetch(url, options)
      .then(response => {
        if (!response.ok) {
          if (/40/.test(`${response.status}`)) {
            onError(MSG_MAP.ERR_NETWORK);
          } else if (/50/.test(`${response.status}`)) {
            onError(MSG_MAP.ERR_SERVICE);
          } else {
            onError(MSG_MAP.ERR_NETWORK);
          }
          console.error(`${response.statusText} ${response.status}`);
        }
        return response;
      });
  };

  try {
    await fetchEventSource(url, {
      openWhenHidden: true,
      method: 'GET',
      fetch: customFetch,
      headers: {
        Authorization: authorization,
      },
      onmessage(ev) {
        const eventData = JSON.parse(ev.data || '{}');
        console.log('message content ===> ', JSON.stringify(eventData));
        switch (eventData.event) {
          case 'message':
            onMessage(eventData.answer);
            break;
          case 'message_end':
            onClose('message_end');
            break;
          case 'message_timeout':
            console.error('message_timeout');
            onError(eventData.msg || MSG_MAP.ERR_SERVICE);
            break;
          case 'message_exception':
            console.error('message_exception');
            onError(eventData.msg || MSG_MAP.ERR_SERVICE);
            break;
          case 'message_limit':
            console.error('message_limit');
            onError(eventData.msg, 'message_limit');
            break;
          default:
            break;
        }
      },
      onerror(error) {
        if (error instanceof TypeError && (error.message.includes('Failed to fetch') || error.message.includes('NetworkError'))) {
          onError(MSG_MAP.ERR_NETWORK);
          console.error(MSG_MAP.ERR_NETWORK, error);
        } else if (error.name === 'AbortError') {
          onError(MSG_MAP.ERR_SERVICE);
          console.error('abort', error);
        } else {
          onError(MSG_MAP.ERR_SERVICE);
          console.error(MSG_MAP.ERR_SERVICE, error);
        }

        throw new Error(error);
      },
      onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          onOpen();
        }
        return Promise.resolve();
      },
      onclose() {
        onClose();
      },
    });
  } catch (error) {
    console.error('Catch fetchEventSource error on streaming ===> ', JSON.stringify(error));
    // onError(MSG_MAP.ERR_NETWORK);
  }
};

export default ({
  params,
  onMessage,
  onError,
  onOpen,
  onClose,
  isInvestment = false,
}: Streaming) => {
  const _params = stringify({
    request_id: params.requestId,
    session_id: params.sessionId,
    conversation_id: params.conversationId,
  });
  const authorization = `Bearer ${localStorage.getItem('iamToken') || ''}`;
  const path = isInvestment ? '/investment/question/streaming/v1' : '/chat/question/streaming/v1';
  const url = `${location.origin}${envVars.portageurBaseUrl}${path}?${_params}`;

  return fetchSSE_2({
    url,
    authorization,
    onMessage,
    onError,
    onOpen,
    onClose,
  });
};

