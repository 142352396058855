import { PropsWithChildren } from 'react';
import { useCommon } from 'store';
import { useAuth0 } from '@auth0/auth0-react';

export type PrivateRouteWrapperProps = PropsWithChildren<{}>;

export const PrivateRouteWrapper = ({ children }: PropsWithChildren) => {
  const { loginWithRedirect, logout } = useAuth0();

  const onLoginWithRedirect = () => {
    localStorage.clear();
    logout();
    loginWithRedirect();
  };
  const isAuthenticated = useCommon(state => state.isAuthenticated);
  return isAuthenticated ? <>{children}</> : <>{onLoginWithRedirect()}</>;
};
