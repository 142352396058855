import React from 'react';
import styles from './index.module.css';
import { Typography, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import request from 'request';
import { parse } from 'query-string';
import moment from 'moment';
import IconFont from 'components/IconFont';

const { Title } = Typography;

interface RenewProps {}

const Renew: React.FC<RenewProps> = () => {
  const params = parse(window.location.search);
  const navigate = useNavigate();
  const [ loading, setLoading ] = React.useState(false);
  const [ isSuccess, setIsSuccess ] = React.useState(false);

  const onRenewPlan = async () => {
    try {
      setLoading(true);
      const { code, msg } = await request.premium.renew();
      setLoading(false);
      if (code === 200) {
        setIsSuccess(true);
      } else {
        message.error(msg);
      }
    } catch (error) {
      message.error('An error occurred, please try again later.');
      setLoading(false);
    }
  };

  const url = window.location.href;

  const link = /test/.test(url)
    ? 'test-portageur.portageb.com'
    : /beta/.test(url)
      ? 'beta-portageur.portageb.com'
      : 'app.portageur.ai';

  return (
    <div className={styles.container}>
      <Title className={styles.title}>Settings</Title>
      <div className={styles.management}>Renew Your Current Plan</div>
      <div className={styles.plan}>
        <div className={styles.top}>
          <div className={styles.top_1}>{params.tier}</div>
          <div className={styles.top_2}></div>
          <div className={styles.top_3}>
            <span>US${params.price}</span>
            <span className={styles.symbol}>{params.billing_period}</span>
          </div>
        </div>
        <div className={styles.bottom}>{params.period}</div>
      </div>
      <div className={styles.info}>
        <p>
          Your plan will no longer be canceled. It will renew on{' '}
          {params?.expired_at && (
            <span>
              {moment.unix(+params?.expired_at).format('MMMM D, YYYY')}
            </span>
          )}
          .
        </p>
      </div>
      <div className={styles.policy}>
        By renewing your plan, you agree to Portageur’s{' '}
        <a href={`https://${link}/auth0/law/terms.html`} target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={`https://${link}/auth0/law/privacy.html`} target="_blank">
          Privacy Policy
        </a>
        .
      </div>
      <div className={styles.footer}>
        {isSuccess ? (
          <Button
            disabled
            className={styles.succesBtn}
            icon={<IconFont type="tick-4VeijwGtkS" style={{ fontSize: 28 }} />}
          />
        ) : (
          <Button
            className={styles.button}
            onClick={onRenewPlan}
            loading={loading}>
            Renew Plan
          </Button>
        )}
        <Button
          className={styles.button}
          onClick={() => {
            navigate('/settings/management');
          }}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default Renew;
