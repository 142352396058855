import React, { useEffect } from "react";
import styles from "./index.module.css";
import Container from "components/Container";
import { Pagination, Typography, Grid, Image } from "antd";
import { usePagination } from "ahooks";
import { PortageurApi } from "request/apis/portageur";
import { ChatHistoryList } from "components/ChatHistoryList";
import { PostFeedbackResponse } from "request/apis/portageurTypes";
import { useAsync } from "hooks/useAsync";
import gTag from "utils/gTag";
import { importGif } from "utils";
import { parse } from "query-string";

const { useBreakpoint } = Grid;

interface ChatHistoryProps {}

const portageurApi = new PortageurApi();

const fetchList = async ({
  current,
  pageSize,
}: {
  current: number;
  pageSize: number;
}) => {
  const res = await portageurApi.getChatHistory({
    current,
    pageSize: pageSize || 10,
  });

  return {
    list: res.data.list,
    total: res.data.total,
  };
};

const ChatHistory: React.FC<ChatHistoryProps> = () => {
  const { data, loading, error, pagination, refreshAsync, run } = usePagination(
    fetchList,
    {
      manual: true,
    },
  );
  const mutateChatQuery = useAsync<PostFeedbackResponse>();
  const query = parse(location.search);

  const [currentPage, setCurrentPage] = React.useState(1);

  useEffect(() => {
    gTag.pageView();
    run({
      current: +query.page || 1,
      pageSize: 10,
    });
  }, []);

  useEffect(() => {
    if (query.page) {
      setCurrentPage(query.page);
    }
  }, [query]);

  const screens = useBreakpoint();

  const onPin = async ({
    sessionId,
    pin,
  }: {
    sessionId: string;
    pin: boolean;
  }) => {
    await mutateChatQuery.runAsync(
      portageurApi.pinChatHistory({ sessionId, pin }),
    );
    await refreshAsync();
  };

  const onRemove = async ({ sessionId }: { sessionId: string }) => {
    await mutateChatQuery.runAsync(
      portageurApi.removeChatHistory({ sessionId }),
    );
    await refreshAsync();
  };

  return (
    <Container width="sm" className={styles.container}>
      {loading && (
        <div className={styles.loading}>
          <Image
            src={importGif("tree")}
            preview={false}
            style={{ width: 140 }}
          />
          <span className={styles.loadingText}>Loading</span>
        </div>
      )}
      {error && (
        <Typography.Text type="danger">There has been an error</Typography.Text>
      )}
      {data && !loading && (
        <div style={{ height: "100%", width: "100%" }}>
          <ChatHistoryList
            onPin={onPin}
            onRemove={onRemove}
            page={currentPage}
            isMutating={mutateChatQuery.status === "pending"}
            items={data?.list.map(
              ({
                service,
                session_id,
                title,
                latest_content,
                latest_time,
                pin,
                types,
              }) => ({
                types,
                service,
                sessionId: session_id,
                title,
                date: new Date(latest_time),
                description: latest_content,
                isPinned: Boolean(pin),
              }),
            )}
          />
          <Pagination
            simple={!screens.lg}
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={data?.total}
            onChange={(page, pageSize) => {
              pagination.onChange(page, pageSize);
              setCurrentPage(page);
              history.pushState(
                { url: location.pathname },
                "",
                location.pathname,
              );
            }}
            showTotal={(total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default ChatHistory;
