import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommon } from 'store';

interface LoginProps {}

const Login: React.FC<LoginProps> = props => {
  const { logout } = useAuth0();
  const { isAuthenticated } = useCommon();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    } else {
      logout();
    }
  }, []);

  return <></>;
};

export default Login;
