import React, { FC, createContext, useContext, useEffect } from 'react';
import { Layout, Image, Button, Grid, Tooltip } from 'antd';
import { importImg } from 'utils';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.css';
import classNames from 'classnames';
import { parseUrl } from 'query-string';
import { useCommon } from 'store';
import { MenuLink, MenuLinkGroup, isMenuLinkGroup } from 'config/interface';
import ProfileMenu from 'components/ProfileMenu';
import IconFont from 'components/IconFont';
import { theme } from '../../theme';
import * as Sentry from '@sentry/browser';
import { SubscriptionModal } from 'components/SubscriptionModal';
import { Header } from 'antd/lib/layout/layout';
import useUsersnap from 'components/useUsersnap';
// import PaymentModal from 'components/PaymentModal';

const { useBreakpoint } = Grid;

const { Sider, Content } = Layout;

export const SidebarContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOpen: (isOpen: boolean) => void 0,
});

export const SubscriptionModalContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsOpen: (isOpen: boolean) => void 0,
});

interface Props {
  children: React.ReactNode;
}

const MenuLink = ({
  collapsed,
  showText,
  onLockedModuleClick,
  ...props
}: (MenuLinkGroup | MenuLink) & {
  collapsed: boolean;
  showText: boolean;
  onLockedModuleClick: () => void;
}) => {
  const location = useLocation();
  const sidebar = useContext(SidebarContext);
  const screens = useBreakpoint();

  const { checkSubscriptionAccess } = useCommon();

  const getSelectedFlag = item => {
    const path = `/${location.pathname.split('/')[1]}`;
    const url = parseUrl(item.path).url;
    return path === url;
  };

  if (isMenuLinkGroup(props)) {
    return (
      <>
        <h4
          className={classNames(
            styles.groupLabel,
            collapsed && styles.groupLabelCollapsed,
          )}
        >
          {props.name}
        </h4>
        {props.links.map((link, index) => (
          <MenuLink
            key={`${link.name}-${index}`}
            {...link}
            onLockedModuleClick={onLockedModuleClick}
            collapsed={collapsed}
            showText={showText}
          />
        ))}
      </>
    );
  }

  const hasAccess = props.availableAtSubscription
    ? checkSubscriptionAccess(props.availableAtSubscription)
    : true;

  const isSelected = getSelectedFlag(props);

  return (
    <Link
      to={props.path}
      onClick={e => {
        if (props.comingSoon || props.disabled) {
          e.preventDefault();
          return;
        }
        if (!hasAccess) {
          e.preventDefault();
          onLockedModuleClick();
          return;
        }
        if (!screens.lg) {
          sidebar.setIsOpen(false);
        }
      }}
      className={classNames(
        styles.menuItem,
        props.orange && styles.menuItemOrange,
        collapsed && styles.menuItemCollapsed,
        isSelected && (props.orange ? styles.selectedOrange : styles.selected),
        (props.disabled || !hasAccess) && styles.disabled,
      )}
    >
      <div className={styles.menuIcon}>
        {!hasAccess || props.disabled
          ? props.disabledIcon?.({ height: 24 }) || props.icon({ height: 24 })
          : isSelected
            ? props.activeIcon({ height: 24 })
            : props.icon({ height: 24 })}
      </div>

      <span
        className={classNames(styles.menuLabel, !showText && styles.hideText)}
        style={{
          color: props.disabled || !hasAccess ? '#808194' : theme.colors.gray,
        }}
      >
        {props.name}
      </span>
      {props.comingSoon && (
        <>
          <Tooltip
            title="Coming Soon"
            style={{ padding: 16 }}
            placement="bottomLeft"
          >
            <span
              className={classNames(
                styles.badge,
                collapsed && styles.badgeCollapsed,
              )}
            >
              <IconFont
                type="clock1"
                style={{
                  color: theme.colors.iconGray,
                  fontSize: 20,
                }}
              />
            </span>
          </Tooltip>
          {showText && (
            <span className={classNames(styles.badgeMobile)}>Coming Soon</span>
          )}
        </>
      )}
      {!hasAccess && !props.comingSoon && (
        <IconFont
          type="lock-icon"
          style={{
            fontSize: 20,
            color: theme.colors.iconGray,
            position: 'absolute',
            bottom: collapsed ? 26 : 14,
            right: collapsed ? 7 : 16,
          }}
        />
      )}
    </Link>
  );
};

const App: FC<Props> = ({ children }) => {
  !import.meta.env.DEV && useUsersnap();
  const screens = useBreakpoint();

  const [ openModal, setOpenModal ] = React.useState(false);

  const [ collapsed, setCollapsed ] = React.useState<boolean>(!screens.lg);

  const { menu, isAuthenticated, user } = useCommon();

  const [ showText, setShowText ] = React.useState<boolean>(!collapsed);

  const toggleCollapsed = () => {
    setCollapsed(collapsed => !collapsed);
  };

  const setIsOpen = (isOpen: boolean) => {
    setCollapsed(!isOpen);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (import.meta.env.PROD) {
      const env = location.href;
      const flag = /test/.test(env)
        ? 'test'
        : /beta/.test(env)
          ? 'beta'
          : /dev/.test(env)
            ? 'dev'
            : 'prod';
      Sentry.setTag('env', flag);
      Sentry.setUser({
        id: user.iam_uid,
        username: user.nickname,
      });
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (collapsed) {
      setShowText(false);
    } else {
      timeout = setTimeout(() => {
        setShowText(true);
      }, 150);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [ collapsed ]);

  useEffect(() => {
    if (
      user?.subscription?.tier &&
      user?.subscription?.tier !== 'premium' &&
      user?.subscription?.tier !== 'basic'
    ) {
      // 在页面加载成功之后展示订阅弹窗，
      // 弹窗内容会根据用户是否订阅来展示不同内容
      setOpenModal(true);
    }
  }, [ user?.subscription?.tier ]);

  useEffect(() => {
    if (screens.lg) {
      setCollapsed(false);
    }
    // } else {
    //   setCollapsed(true);
    // }
  }, [ screens.lg ]);

  const onMouseEnter = () => {
    if (!screens.md) return;
    if (collapsed) {
      toggleCollapsed();
    }
  };

  const onMouseLeave = () => {
    if (screens.lg) return;
    if (!collapsed && screens.md) toggleCollapsed();
  };
  if (!isAuthenticated && !user) {
    return <Layout className={styles.layout}>{children}</Layout>;
  }

  return (
    <SidebarContext.Provider value={{ isOpen: !collapsed, setIsOpen }}>
      <SubscriptionModalContext.Provider
        value={{ isOpen: openModal, setIsOpen: setOpenModal }}
      >
        <SubscriptionModal isOpen={openModal} onClose={onModalClose} />
        {/* <PaymentModal /> */}
        <Button
          className={classNames(
            collapsed && styles.toggleButtonCollapsed,
            styles.toggleButton,
          )}
          shape="circle"
          type={screens.lg ? 'default' : 'text'}
          icon={
            !collapsed && !screens.lg ? (
              <IconFont
                type="cross"
                style={{ color: theme.colors.iconGray, fontSize: 20 }}
              />
            ) : (
              <IconFont
                type="phone-menu"
                style={{ color: '#3a404d', fontSize: 28 }}
              />
            )
          }
          onClick={toggleCollapsed}
        />
        <Layout className={styles.layout}>
          <Sider
            {...(screens.md
              ? {
                width: 242,
                collapsedWidth: 88,
                style: {
                  position: 'relative',
                  zIndex: 100,
                  height: '100%',
                  overflow: 'auto',
                  transition: '600ms',
                },
              }
              : {
                width: '100%',
                collapsedWidth: 0,
                style: {
                  position: 'fixed',
                  zIndex: 100,
                  top: 0,
                  left: 0,
                  height: '100%',
                  overflow: 'auto',
                  transition: '600ms',
                },
              })}
            collapsed={collapsed}
          >
            <div
              className={styles.itemsWrapper}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <div>
                <div className={styles.logoWrapper}>
                  <Image
                    preview={false}
                    src={
                      collapsed
                        ? importImg('logo-collapsed')
                        : importImg('logoWrap')
                    }
                    className={collapsed ? styles.logoCollapsed : styles.logo}
                  />
                </div>
                <ul className={styles.menu}>
                  {menu.map((item, index) => {
                    return (
                      <MenuLink
                        key={index}
                        collapsed={collapsed}
                        showText={showText}
                        onLockedModuleClick={() => {
                          setOpenModal(true);
                        }}
                        {...item}
                      />
                    );
                  })}
                </ul>
              </div>
              <ProfileMenu
                username={
                  user?.first_name
                    ? `${user?.first_name} ${user?.last_name}`
                    : user?.nickname || ''
                }
                profilePicture={user?.picture}
                className={styles.profileMenu}
                collapsed={collapsed}
                showText={showText}
                subscriptionTier={user?.subscription?.tier}
                subscriptionDaysLeft={user?.subscription?.daysLeft}
                subscription_days_left_display={
                  user?.subscription_days_left_display
                }
              />
            </div>
          </Sider>
          <Layout style={{ position: 'relative' }}>
            <Header className={styles.header} />
            <Content className={styles.content}>{children}</Content>
          </Layout>
        </Layout>
      </SubscriptionModalContext.Provider>
    </SidebarContext.Provider>
  );
};
export default App;
