import type { SerializedLexicalNode, Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export type SerializedSectorNode = Spread<
{
  sectorName: string;
  type: 'sector';
  version: 1;
  code: string;
},
SerializedTextNode
>;

function convertSectorElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createSectorNode(textContent, '');
    return {
      node,
    };
  }

  return null;
}

const SectorStyle = 'color: #ff9c32';
export class SectorNode extends TextNode {
  __sector: string;
  __code: string;

  static getType(): string {
    return 'Sector';
  }

  static isSerializedNode(
    node: SerializedLexicalNode,
  ): node is SerializedSectorNode {
    return node.type === this.getType();
  }

  static clone(node: SectorNode): SectorNode {
    return new SectorNode(node.__sector, node.__code, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedSectorNode): SectorNode {
    const node = $createSectorNode(
      serializedNode.sectorName,
      serializedNode.code,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(sectorName: string, code: string, text?: string, key?: NodeKey) {
    super(text ?? sectorName, key);
    this.__sector = sectorName;
    this.__code = code;
  }

  exportJSON(): SerializedSectorNode {
    return {
      ...super.exportJSON(),
      sectorName: this.__Sector,
      type: 'sector',
      version: 1,
      code: this.__code,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = SectorStyle;
    dom.className = 'sector';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-sector', 'true');
    element.textContent = this.__text;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-sector')) {
          return null;
        }
        return {
          conversion: convertSectorElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createSectorNode(
  sectorName: string,
  code: string,
): SectorNode {
  const sectorNode = new SectorNode(sectorName, code);
  sectorNode.setMode('segmented').toggleDirectionless();
  return sectorNode;
}

export function $isSectorNode(
  node: LexicalNode | null | undefined,
): node is SectorNode {
  return node instanceof SectorNode;
}
