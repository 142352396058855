import 'antd/dist/antd.less';
import 'assets/fonts/index.css';
import React from 'react';
import App from './providers/App';
import moment from 'moment';
import { createRoot } from 'react-dom/client';
import { message } from 'antd';
// import VConsole from 'vconsole';

import './index.css';
moment.locale('en');

message.config({
  maxCount: 2,
});

// import.meta.env.DEV && new VConsole();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);
