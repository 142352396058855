import { getCssVar } from 'utils';

export const theme = {
  colors: {
    orange: getCssVar('--orange'),
    black: getCssVar('--black'),
    gray: getCssVar('--gray'),
    green: getCssVar('--green'),
    lightGray: getCssVar('--light-gray'),
    white: getCssVar('--white'),
    middleGray: getCssVar('--middle-gray'),
    iconGray: getCssVar('--icon-gray'),
  },
};
