import { Message, OnReference, Reference } from './types';
import styles from './BotMessage.module.css';
import classNames from 'classnames';
import { Dropdown, Typography } from 'antd';
import React, { useMemo, useRef } from 'react';
import { FeedbackOptions } from 'request/apis/portageurTypes';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { theme } from '../../theme';
import IconFont from 'components/IconFont';
import MarkdownRenderer from 'components/MarkdownRenderer';
// import { useTypewriterEffect } from 'hooks/useTypewriterEffect';
import gTag from 'utils/gTag';
import { Reference as PDFReference } from 'request/apis/portageurTypes';
import { useUpdateEffect } from 'ahooks';
import { ChatContext } from 'components/Chat/useContext';

export type BotMessageProps = Message & {
  status: string;
  className?: string;
  onClick?: (args: { requestId: string }) => void;
  feedbackOptions: FeedbackOptions | null;
  onFeedback?: (args: { feedbackId: number; requestId: number }) => void;
  onReference?: OnReference;
  disableAnimation?: boolean;
  openReference?: boolean;
  pdf?: PDFReference[];
  autoScrollBottom: (v?: boolean) => void;
};

export const BotMessage = ({
  status,
  message,
  className,
  feedbackOptions,
  onFeedback,
  requestId,
  feedback,
  pdf,
  references,
  onReference,
  openReference,
  autoScrollBottom,
}: BotMessageProps) => {
  const { isInvestment } = React.useContext(ChatContext);

  // const omitIndexes = useMemo(() => {
  //   const tableRows = message.match(/^\|.*\|\s*$/gm);

  //   if (!tableRows?.[0]) {
  //     return undefined;
  //   }

  //   const index = message.indexOf(tableRows[0]);

  //   const resumeAt =
  //     index + tableRows[0].length + (tableRows[1]?.length || 0) + 2;

  //   return [{ index, resumeAt }];
  // }, [ message ]);

  // const typeWriterText = useTypewriterEffect(
  //   disableAnimation ? '' : message,
  //   6,
  //   omitIndexes,
  // );

  // useUpdateEffect(() => {
  //   if (typeWriterText.length === message.length) {
  //     setAppearReference(true);
  //     countRef.current = 0;
  //   } else {
  //     autoScrollBottom?.(!countRef.current);
  //     countRef.current++;
  //   }
  // }, [ typeWriterText ]);

  useUpdateEffect(() => {
    if (/typeWriter|error/.test(status)) {
      autoScrollBottom?.(false);
    }
  }, [ message ]);

  return (
    <div
      className={classNames(
        styles.container,
        className,
        openReference && styles.openContainer,
      )}
    >
      <div className={styles.contentWrapper}>
        <MarkdownRenderer
          tableWrapperStyle={{ width: 'min(calc(100vw - 72px), 515px)' }}
          markdown={
            status === 'typeWriter' && !isInvestment
              ? `${JSON.stringify(message)
                .replace(/\\\\n/g, '\n')
                .replace(/^"|"$/g, '')}|`
              : message
          }
        />

        {Boolean(references?.length) && (
          <References
            className={styles.referenceTransition}
            onReference={onReference}
            references={references || []}
            pdf={pdf || []}
          />
        )}
      </div>
      {status !== 'question' && (
        <Feedback
          feedbackOptions={feedbackOptions}
          onFeedback={onFeedback}
          feedback={feedback}
          requestId={requestId}
          message={message}
        />
      )}
    </div>
  );
};

export function References({
  pdf,
  references,
  onReference,
  className,
}: {
  pdf: PDFReference[];
  references: Reference[];
  onReference?: OnReference;
  className?: string;
}) {
  const onReferenceClick = (reference: Reference, index) => () => {
    if (reference.type === 'pdf') {
      onReference?.(reference, pdf[index]);
    } else {
      window.open(reference.link, '_blank');
    }
  };
  return (
    <div className={classNames(styles.referencesWrapper, className)}>
      <Typography.Text style={{ fontWeight: 600, display: 'block', width: 80 }}>
        Reference:
      </Typography.Text>
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <ul className={styles.references}>
          {references.map((value, index) => (
            <li
              key={index}
              className={styles.referenceItem}
              onClick={onReferenceClick(value, index)}
            >
              <IconFont
                type={value.type === 'web' ? 'icons3' : 'review'}
                style={{ fontSize: 20, color: theme.colors.orange }}
              />
              <Typography.Text
                underline
                style={{
                  fontSize: 14,
                  display: 'block',
                  width: 'max-content',
                  color: theme.colors.orange,
                }}
              >
                {value.name}
              </Typography.Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

type FeedbackProps = {
  feedbackOptions: FeedbackOptions | null;
  onFeedback?: (args: { feedbackId: number; requestId: number }) => void;
  feedback: Message['feedback'];
  requestId: number;
  message: string;
};

function Feedback({
  feedbackOptions,
  onFeedback,
  feedback,
  requestId,
  message,
}: FeedbackProps) {
  const [ text, setText ] = React.useState<string>('');

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setText('');
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [ text ]);

  const onCopyClick = () => {
    setText('Copied to clipboard');
    navigator.clipboard.writeText(message);
    gTag.copy();
  };

  const onFeedbackClick = (feedbackId: number | null) => () => {
    if (onFeedback && feedbackId) {
      setText('Thank you for your feedback');
      onFeedback({ feedbackId, requestId });
    }
  };

  const { dislikeMenu, likeId } = React.useMemo(() => {
    let likeId: number | null = null;
    const dislikeMenu: ItemType[] = [];
    if (feedbackOptions) {
      likeId = feedbackOptions?.positive?.[0]?.feedback_id;
      for (const value of feedbackOptions.negative) {
        dislikeMenu.push({
          key: value.feedback_id,
          label: (
            <div
              onClick={onFeedbackClick(value.feedback_id)}
              style={{ width: '100%', height: '100%' }}
            >
              <Typography.Text>{value.feedback}</Typography.Text>
            </div>
          ),
        });
      }
    }
    return { dislikeMenu, likeId };
  }, [ feedbackOptions ]);

  React.useEffect(() => {
    if (feedback?.status === 'rejected') {
      setText('Something went wrong');
    }
  }, [ feedback ]);

  const { likeActive, dislikeActive } = React.useMemo(() => {
    const likeActive = feedback?.feedbackId === likeId;
    const dislikeActive = feedbackOptions?.negative.some(
      value => value.feedback_id === feedback?.feedbackId,
    );

    return {
      likeActive,
      dislikeActive,
    };
  }, [ feedback, feedbackOptions ]);

  return (
    <div className={styles.feedbackWrapper}>
      <IconFont
        type="thumb-up"
        onClick={onFeedbackClick(likeId)}
        className={classNames(styles.icon, likeActive && styles.active)}
      />
      <Dropdown
        trigger={[ 'click' ]}
        placement="top"
        menu={{ items: dislikeMenu }}
      >
        <IconFont
          type="thumb-down"
          className={classNames(styles.icon, dislikeActive && styles.active)}
        />
      </Dropdown>
      <IconFont
        type="clipboard"
        onClick={onCopyClick}
        className={styles.icon}
      />
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
        {text}
        {/* <span style={{ opacity: 0, userSelect: 'none' }}>Prevent</span>*/}
      </Typography.Text>
    </div>
  );
}
