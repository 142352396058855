import type { SerializedLexicalNode, Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export type SerializedIndustryNode = Spread<
{
  industryName: string;
  type: 'industry';
  version: 1;
  code: string;
},
SerializedTextNode
>;

function convertIndustryElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createIndustryNode(textContent, '');
    return {
      node,
    };
  }

  return null;
}

const IndustryStyle = 'color: #ff9c32';
export class IndustryNode extends TextNode {
  __industry: string;
  __code: string;

  static getType(): string {
    return 'industry';
  }

  static isSerializedNode(
    node: SerializedLexicalNode,
  ): node is SerializedIndustryNode {
    return node.type === this.getType();
  }

  static clone(node: IndustryNode): IndustryNode {
    return new IndustryNode(
      node.__industry,
      node.__code,
      node.__text,
      node.__key,
    );
  }
  static importJSON(serializedNode: SerializedIndustryNode): IndustryNode {
    const node = $createIndustryNode(
      serializedNode.industryName,
      serializedNode.code,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(
    industryName: string,
    code: string,
    text?: string,
    key?: NodeKey,
  ) {
    super(text ?? industryName, key);
    this.__industry = industryName;
    this.__code = code;
  }

  exportJSON(): SerializedIndustryNode {
    return {
      ...super.exportJSON(),
      industryName: this.__Industry,
      type: 'industry',
      version: 1,
      code: this.__code,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = IndustryStyle;
    dom.className = 'industry';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-industry', 'true');
    element.textContent = this.__text;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-industry')) {
          return null;
        }
        return {
          conversion: convertIndustryElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createIndustryNode(
  industryName: string,
  code: string,
): IndustryNode {
  const industryNode = new IndustryNode(industryName, code);
  industryNode.setMode('segmented').toggleDirectionless();
  return industryNode;
}

export function $isIndustryNode(
  node: LexicalNode | null | undefined,
): node is IndustryNode {
  return node instanceof IndustryNode;
}
