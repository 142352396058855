import type { SerializedLexicalNode, Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export type SerializedCompanyNode = Spread<
{
  companyName: string;
  type: 'company';
  version: 1;
  code: string;
},
SerializedTextNode
>;

function convertCompanyElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createCompanyNode(textContent, '');
    return {
      node,
    };
  }

  return null;
}

const companyStyle = 'color: #ff9c32';
export class CompanyNode extends TextNode {
  __company: string;
  __code: string;

  static getType(): string {
    return 'company';
  }

  static isSerializedNode(
    node: SerializedLexicalNode,
  ): node is SerializedCompanyNode {
    return node.type === this.getType();
  }

  static clone(node: CompanyNode): CompanyNode {
    return new CompanyNode(
      node.__company,
      node.__code,
      node.__text,
      node.__key,
    );
  }
  static importJSON(serializedNode: SerializedCompanyNode): CompanyNode {
    const node = $createCompanyNode(
      serializedNode.companyName,
      serializedNode.code,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(companyName: string, code: string, text?: string, key?: NodeKey) {
    super(text ?? companyName, key);
    this.__company = companyName;
    this.__code = code;
  }

  exportJSON(): SerializedCompanyNode {
    return {
      ...super.exportJSON(),
      companyName: this.__company,
      type: 'company',
      version: 1,
      code: this.__code,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = companyStyle;
    dom.className = 'company';
    dom.setAttribute('data-lexical-company', 'true');
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-company', 'true');
    element.textContent = this.__text;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-company')) {
          return null;
        }
        return {
          conversion: convertCompanyElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createCompanyNode(
  companyName: string,
  code: string,
): CompanyNode {
  const companyNode = new CompanyNode(companyName, code);
  companyNode.setMode('segmented').toggleDirectionless();
  return companyNode;
}

export function $isCompanyNode(
  node: LexicalNode | null | undefined,
): node is CompanyNode {
  return node instanceof CompanyNode;
}
