import React from "react";
import { Message } from "./types";
import styles from "./ErrorMessage.module.css";
import { importImg } from "utils";
import { Typography, Image } from "antd";
import classNames from "classnames";
import { ChatContext } from "components/Chat/useContext";

export type ErrorMessageProps = Message & {
  className?: string;
  onResend?: () => void;
  retryCount: number;
  isLastItem: boolean;
};

export const ErrorMessage = ({
  status,
  message,
  className,
  onResend,
  retryCount,
  isLastItem,
}: ErrorMessageProps) => {
  const { conversationId } = React.useContext(ChatContext);

  return (
    <div className={classNames(className, styles.container)}>
      <div className={classNames(styles.textWrapper)}>
        <Typography.Text>
          {message || "An error occurred. Please, try again!"}
        </Typography.Text>
        <Image
          src={importImg("alert")}
          className={styles.alertIcon}
          style={{ width: 20, height: 20 }}
          preview={false}
        />
      </div>
      {isLastItem &&
        status === "retry" &&
        retryCount !== 3 &&
        conversationId && (
          <Typography.Text
            className={styles.resend}
            onClick={onResend}
            underline
            style={{ font: "bold" }}>
            Resend prompt
          </Typography.Text>
        )}
    </div>
  );
};
