import { Button, Typography, Progress } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  // PdfLoader,
  PdfHighlighter,
  AreaHighlight,
  IHighlight,
  Highlight,
} from 'react-pdf-highlighter';
import PdfLoader from './Loader';
import { Highlight as High } from 'request/apis/portageurTypes';
import styles from './index.module.css';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import IconFont from 'components/IconFont';
import { v1 } from 'uuid';

export type PdfDisplayProps = {
  url: string;
  highlights: High[];
  title: string;
  enableFullscreen?: boolean;
  scrollTargetContent?: string;
};

const PdfDisplay = ({
  url,
  highlights,
  title,
  enableFullscreen,
}: PdfDisplayProps) => {
  const [ fullscreen, setFullscreen ] = useState(false);
  const pdfHighlighter = useRef<PdfHighlighter<IHighlight>>(null);
  const pdf = useRef<PdfLoader>(null);
  const [ loaded, setLoaded ] = useState(false);
  const [ uuid, setUuid ] = useState(v1());

  const turnOnFullscreen: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    if (pdfHighlighter.current?.viewer.viewer) {
      // console.log(pdfHighlighter.current?.viewer.viewer);
      pdfHighlighter.current?.resizeObserver?.observe(
        pdfHighlighter.current?.viewer.container,
      );
    }
    setFullscreen(true);
  };

  const turnOffFullscreen = () => {
    setFullscreen(false);
  };

  const zoomIn: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    pdfHighlighter.current?.resizeObserver?.disconnect();
    pdfHighlighter.current?.viewer.increaseScale(0.1);
  };

  const zoomOut: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    pdfHighlighter.current?.resizeObserver?.disconnect();

    pdfHighlighter.current?.viewer._setScale(
      pdfHighlighter.current?.viewer._currentScale - 0.1,
    );
  };

  useEffect(() => {
    const handleClick = (e: Event) => {
      e.stopPropagation();
    };
    const pdfViewer = document.querySelector('.pdfViewer');
    if (loaded) {
      pdfViewer?.addEventListener('click', handleClick);
    }

    return () => {
      pdfViewer?.removeEventListener('click', handleClick);
    };
  }, [ loaded, highlights ]);

  useEffect(() => {
    try {
      highlights?.length &&
        pdfHighlighter?.current?.scrollTo?.(highlights?.[0] as any);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [ highlights ]);

  return (
    <div
      onClick={turnOffFullscreen}
      className={classNames(fullscreen ? styles.fullscreen : styles.wrapper)}>
      {!fullscreen && (
        <div className={styles.header}>
          <IconFont type="document-pdf" style={{ fontSize: 24 }} />
          <Typography.Text style={{ fontWeight: 'bold' }}>
            {title}
          </Typography.Text>
        </div>
      )}
      {fullscreen && (
        <div className={styles.fullScreenIcon}>
          <IconFont
            type="unexpand"
            onClick={turnOffFullscreen}
            style={{ fontSize: 24, cursor: 'pointer', color: '#555657' }}
          />
        </div>
      )}
      <div className={classNames(styles.container)}>
        <div className={styles.pdfWrapper}>
          <PdfLoader
            ref={pdf}
            key={uuid}
            workerSrc="https://beta-portageur.portageb.com/pdf.worker.min.js"
            url={
              import.meta.env.DEV
                ? url.replace('https://portagebay.azureedge.net', '')
                : url
            }
            errorMessage={
              <Button
                icon={<IconFont type="Redo" style={{ color: '#000' }} />}
                className={styles.retry}
                onClick={() => setUuid(v1())}>
                Retry
              </Button>
            }
            beforeLoad={progress => (
              <Progress
                className={styles.spinner}
                type="circle"
                percent={progress}
                strokeColor={'#ff9c32'}
              />
            )}>
            {pdfDocument => {
              setLoaded(true);
              return (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  ref={pdfHighlighter}
                  scrollRef={scrollTo => {
                    const highlight = highlights[0];
                    if (!highlight) return;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    scrollTo(highlight);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  highlights={highlights}
                  handleScaleValue={scale => {}}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo,
                  ) => {
                    const isTextHighlight = !(
                      highlight.content && highlight.content.image
                    );

                    const component = isTextHighlight ? (
                      <Highlight
                        key={index}
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <AreaHighlight
                        key={index}
                        isScrolledTo={isScrolledTo}
                        highlight={highlight}
                        onChange={boundingRect => {}}
                      />
                    );

                    return component;
                  }}
                  onScrollChange={() => {}}
                  onSelectionFinished={() => {
                    return <span />;
                  }}
                  enableAreaSelection={e => {
                    e.stopPropagation();
                    return false;
                  }}
                />
              );
            }}
          </PdfLoader>
        </div>
        {!fullscreen && (
          <div className={styles.iconsWrapper}>
            {enableFullscreen && (
              <IconFont
                type="expand"
                onClick={turnOnFullscreen}
                style={{ fontSize: 24, cursor: 'pointer' }}
              />
            )}
            <PlusCircleFilled
              onClick={zoomIn}
              style={{ fontSize: 24, cursor: 'pointer' }}
            />
            <MinusCircleFilled
              onClick={zoomOut}
              style={{ fontSize: 24, cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfDisplay;
