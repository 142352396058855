import classNames from 'classnames';
import { ChatHistoryItem, ChatHistoryItemProps } from './ChatHistoryItem';
import styles from './index.module.css';

export type ChatHistoryListProps = {
  className?: string;
  items: Omit<ChatHistoryItemProps, 'onPin' | 'onRemove'>[];
  onRemove: (args: { sessionId: string }) => void;
  onPin: (args: { sessionId: string; pin: boolean }) => void;
  isMutating?: boolean;
  page?: number;
};

export const ChatHistoryList = ({
  className,
  items,
  onPin,
  onRemove,
  isMutating,
  page,
}: ChatHistoryListProps) => {
  return (
    <>
      <div style={{ height: 22, width: '100%' }}></div>
      <div className={classNames(className, styles.container)}>
        {items.map((item, index) => {
          return (
            <ChatHistoryItem
              key={`${item.sessionId}-${index}`}
              onPin={onPin}
              onRemove={onRemove}
              isMutating={isMutating}
              page={page}
              {...item}
            />
          );
        })}
      </div>
    </>
  );
};
