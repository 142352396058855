import Chat from 'components/Chat';
import React, { useEffect } from 'react';
import gTag from 'utils/gTag';

interface LibraryChatProps {}

const LibraryChat: React.FC<LibraryChatProps> = () => {

  useEffect(() => {
    gTag.pageView();
  }, []);

  return <Chat />;
};

export default LibraryChat;
