import React, { useState, useEffect } from 'react';
import { Modal, Grid } from 'antd';
import { StripePricingTable } from 'components/StripePricingTable';
import { useCommon } from 'store';
import TrialTips from './TrialTips';
import styles from './index.module.css';
import IconFont from '../IconFont/index';

const { useBreakpoint } = Grid;

export type SubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscriptionModal = ({
  isOpen,
  onClose,
}: SubscriptionModalProps) => {
  const { user } = useCommon();
  const screens = useBreakpoint();
  const [ openTrialTips, setOpenTrialTips ] = useState(true);

  useEffect(() => {
    if (
      user?.subscription?.tier && (user?.subscription?.tier === 'free' || user?.subscription?.tier === 'basic')
    ) {
      setOpenTrialTips(false);
    }
  }, [ user?.subscription?.tier ]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        onClose();
        setTimeout(() => {
          setOpenTrialTips(false);
        }, 1000);
      }}
      width={openTrialTips ? 560 : 1000}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      bodyStyle={{ padding: screens.lg ? '64px 48px 48px 48px' : '66px 20px' }}
      className={styles.modal}
      closeIcon={(<IconFont type="cross" style={{ fontSize: screens.md ? 24 : 32, paddingTop: screens.md ? 24 : 14, paddingRight: 24 }} />)}
    >
      <div style={{ display: openTrialTips ? 'block' : 'none' }}>
        <TrialTips setOpenTrialTips={setOpenTrialTips} onClose={onClose} name={user?.first_name}/>
      </div>
      <div style={{ display: !openTrialTips ? 'block' : 'none' }}>
        <StripePricingTable
          customerEmail={user?.email || ''}
          customerId={String(user?.iam_uid) || ''}
        />
      </div>
    </Modal>
  );
};

