import React, { useContext } from 'react';
import styles from './index.module.css';
import { Button } from 'antd';
import { useCommon } from 'store';
import { SidebarContext, SubscriptionModalContext } from 'components/Layout';

interface UpgradeToPremiumProps {}

const UpgradeToPremium: React.FC<UpgradeToPremiumProps> = () => {
  const { user } = useCommon();
  const subscriptionModal = useContext(SubscriptionModalContext);
  const sidebar = useContext(SidebarContext);

  return user?.subscription?.tier && (user?.subscription?.tier !== 'premium' && user?.subscription?.tier !== 'basic') && sidebar.isOpen ?
    <div className={styles.container}>
      <Button className={styles.button} onClick={() => {
        subscriptionModal.setIsOpen(true);
      }}>
        Upgrade Plan
      </Button></div>
    : null;
};

export default UpgradeToPremium;
