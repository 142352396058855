import { Typography } from 'antd';
import { CompanyNode } from 'components/PromptInput/nodes/CompanyNode';
import { IndustryNode } from 'components/PromptInput/nodes/IndustryNode';
import { MetricNode } from 'components/PromptInput/nodes/MetricNode';
import { SectorNode } from 'components/PromptInput/nodes/SectorNode';
import {
  EditorState,
  LexicalNode,
  ParagraphNode,
  RootNode,
  SerializedLexicalNode,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export function isSerializedTextNode(
  node: SerializedLexicalNode,
): node is SerializedTextNode {
  return node.type === TextNode.getType();
}

export function hasChildren(
  node: SerializedLexicalNode,
): node is SerializedLexicalNode & { children: SerializedLexicalNode[] } {
  return 'children' in node;
}

export function getStringFromEditorState(editorState: EditorState) {
  let result = '';

  const serializedEditorState = editorState.toJSON();

  function _getString(nodes: SerializedLexicalNode[]) {
    // Replace the nodes with their codes
    for (const value of nodes) {
      if (CompanyNode.isSerializedNode(value)) {
        result += `[code:${value.code}]`;
      } else if (MetricNode.isSerializedNode(value)) {
        result += `[metric:${value.code}]`;
      } else if (SectorNode.isSerializedNode(value)) {
        result += `[sector:${value.code}]`;
      } else if (IndustryNode.isSerializedNode(value)) {
        result += `[industry:${value.code}]`;
      } else if (isSerializedTextNode(value)) {
        result += value.text;
      } else if (hasChildren(value)) {
        result += ' ';
        _getString(value.children);
      }
    }
  }

  _getString(serializedEditorState.root.children);

  return result.trim();
}

export function getReactNodesFromEditorState(editorState: EditorState) {
  const serializedEditorState = editorState.toJSON();

  function _mapSerializedNodesToReactNodes(nodes: SerializedLexicalNode[]) {
    return (
      <Typography.Text>
        {nodes.map(value => {
          if (
            CompanyNode.isSerializedNode(value) ||
            MetricNode.isSerializedNode(value) ||
            SectorNode.isSerializedNode(value) ||
            IndustryNode.isSerializedNode(value)
          ) {
            return <span className="highlight">{value.text}</span>;
          } else if (isSerializedTextNode(value)) {
            return value.text;
          } else if (hasChildren(value)) {
            return _mapSerializedNodesToReactNodes(value.children);
          }
          return null;
        })}
      </Typography.Text>
    );
  }
  return _mapSerializedNodesToReactNodes(serializedEditorState.root.children);
}

export function deepClearNodes(root: RootNode | LexicalNode) {
  root.getChildren().forEach(child => {
    // Remove all children of nodes that can have children to make sure that there are no nodes without parents
    if (child instanceof ParagraphNode) {
      deepClearNodes(child);
    }
    // Remove the node itself
    child.remove();
  });
}
