import { Reducer, useCallback, useReducer } from 'react';
import useSafeDispatch from './useSafeDispatch';

export type AsyncAction = 'pending' | 'resolved' | 'rejected';

type Idle = 'idle';

export type Status = AsyncAction | Idle;

type AsyncActionType<T> =
  | {
    type: 'pending';
  }
  | {
    type: 'resolved';
    data: T;
  }
  | {
    type: 'rejected';
    error: any;
  };

type StateType<T> = {
  data: T | null;
  status: Status;
  error: any | null;
};

function asyncReducer<T>(
  _: StateType<T>,
  action: AsyncActionType<T>,
): StateType<T> {
  switch (action.type) {
    case 'pending': {
      return { status: 'pending', data: null, error: null };
    }
    case 'resolved': {
      return { status: 'resolved', data: action.data, error: null };
    }
    case 'rejected': {
      return { status: 'rejected', data: null, error: action.error };
    }
    default: {
      throw new Error('Unhandled AsyncAction type');
    }
  }
}

export const useAsync = <T>(initialState?: T) => {
  const [ state, unSafeDispatch ] = useReducer<
  Reducer<StateType<T>, AsyncActionType<T>>
  >(asyncReducer, {
    status: 'idle',
    data: initialState || null,
    error: null,
  });

  const dispatch = useSafeDispatch(unSafeDispatch);

  const { data, error, status } = state;

  const runAsync = useCallback(
    (promise: Promise<T>) => {
      dispatch({ type: 'pending' });
      return promise.then(
        data => {
          dispatch({ type: 'resolved', data });
          return data;
        },
        error => {
          dispatch({ type: 'rejected', error });
          return Promise.reject(error);
        },
      );
    },
    [ dispatch ],
  );

  const setData = useCallback(
    (data: T) => {
      dispatch({ type: 'resolved', data });
    },
    [ dispatch ],
  );

  const setError = useCallback(
    error => {
      dispatch({ type: 'rejected', error });
    },
    [ dispatch ],
  );

  return {
    error,
    status,
    data,
    runAsync,
    setData,
    setError,
  };
};
