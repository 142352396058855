import { SubscriptionTier } from 'request/apis/portageurTypes';

declare global {
  interface Window {
    [key: string]: any;
  }
}

type MenuLinkIcon = (props: { height: number }) => React.ReactNode;

export type MenuLink = {
  name: string;
  comingSoon?: boolean;
  disabled?: boolean;
  orange?: boolean;
  path: string;
  icon: MenuLinkIcon;
  activeIcon: MenuLinkIcon;
  disabledIcon?: MenuLinkIcon;
  availableAtSubscription?: SubscriptionTier[];
  notAllowedIcon?: MenuLinkIcon;
};

export type MenuLinkGroup = {
  name: string;
  links: MenuLink[];
};

export type MenuLinks = (MenuLinkGroup | MenuLink)[];

export function isMenuLinkGroup(
  link: MenuLink | MenuLinkGroup,
): link is MenuLinkGroup {
  return (link as MenuLinkGroup).links !== undefined;
}

export interface Child {
  path: string;
  key?: string;
  name: string;
  icon?: string;
}

export interface User {
  subscription_days_left_display: string;
  last_name: any;
  subscription_days_left: number;
  first_name: string;
  auth0_uid: string;
  iam_uid: number;
  stripe_uid: string;
  email: string;
  email_verified: number;
  nickname: string;
  picture: string;
  iss: string;
  iat: number;
  exp: number;
  subscription_status: number;
  subscription_tier: string;
  subscription_expired_at: number;
  newUser: boolean;
  subscription: {
    tier: SubscriptionTier;
    expiresAt: string;
    isSubscribed: boolean;
    daysLeft: number;
  };
}
