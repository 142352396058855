import { Typography, Image } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { importGif } from 'utils';
import { ChatStatus } from 'request/apis/portageurTypes';
import { CloseCircleFilled } from '@ant-design/icons';

export type ChatRequestStatusProps = {
  message: string;
  className?: string;
  status: ChatStatus;
};

const ChatRequestStatus = ({
  message,
  className,
  status,
}: ChatRequestStatusProps) => {
  const [ queue, setQueue ] = useState<string[]>([]);

  useEffect(() => {
    setQueue(prev => [ ...prev, message ]);
  }, [ message ]);

  return (
    <div className={classNames(styles.container, className)}>
      {status === 'progress' && (
        <Image
          src={importGif('tree')}
          preview={false}
          className={styles.image}
        />
      )}
      {status === 'error' && (
        <CloseCircleFilled
          style={{ marginRight: 10, fontSize: 24, color: 'red' }}
        />
      )}

      <div className={styles.textWrapper}>
        {queue.slice(0, 3).map((state, index) => {
          const isFirstChild = index === 0;
          const isSecondChild = index === 1;
          const fadeOut = index === 1 && queue.length > 1;
          return (
            <Typography.Text
              className={classNames(
                styles.text,
                isFirstChild && styles.first,
                fadeOut && styles.fadeOut,
                isSecondChild && styles.fadeIn,
              )}
              onAnimationEnd={e => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (e.nativeEvent.target?.classList?.contains(styles.fadeIn)) {
                  setQueue(prev => {
                    const [ , ...rest ] = prev;
                    return rest;
                  });
                }
              }}
              key={`${state}-${index}`}
            >
              {state}
            </Typography.Text>
          );
        })}
      </div>
    </div>
  );
};

export default ChatRequestStatus;
