import { useEffect } from 'react';
import { useCommon } from 'store';

const UseUsersnap = () => {
  const { user: { email } } = useCommon();

  useEffect(() => {
    if (email) {
      window.onUsersnapCXLoad = function(api) {
        api.init({
          user: {
            email,
          },
        });
      };
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://widget.usersnap.com/global/load/228dc4de-c664-4f53-8915-a8efed2b2bd0?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);

      return () => {
        document.getElementsByTagName('head')[0].removeChild(script);
      };
    }
  }, [ email ]);

};

export default UseUsersnap;
