import React, { Component } from 'react';

import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import type { PDFDocumentProxy, PDFDocumentLoadingTask } from 'pdfjs-dist';

interface Props {
  /** See `GlobalWorkerOptionsType`. */
  workerSrc: string;

  url: string;
  beforeLoad: JSX.Element | ((progress: number) => JSX.Element);
  errorMessage?: JSX.Element;
  children: (pdfDocument: PDFDocumentProxy) => JSX.Element;
  onError?: (error: Error) => void;
  cMapUrl?: string;
  cMapPacked?: boolean;
  onProgress?: (progress: number) => void;
}

interface State {
  pdfDocument: PDFDocumentProxy | null;
  loadingTask: PDFDocumentLoadingTask | null;
  error: Error | null;
  progress: number;
}

class Loader extends Component<Props, State> {
  state: State = {
    pdfDocument: null,
    loadingTask: null,
    error: null,
    progress: 0,
  };

  static defaultProps = {
    workerSrc: 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
  };

  documentRef = React.createRef<HTMLElement>();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    const { pdfDocument: discardedDocument } = this.state;
    if (discardedDocument) {
      discardedDocument.destroy();
    }
  }

  componentDidUpdate({ url }: Props) {
    if (this.props.url !== url) {
      this.load();
    }
  }

  componentDidCatch(error: Error, info?: any) {
    const { onError } = this.props;

    if (onError) {
      onError(error);
    }

    this.setState({ pdfDocument: null, error });
  }

  load() {
    const { ownerDocument = document } = this.documentRef.current || {};
    const { url, cMapUrl, cMapPacked, workerSrc } = this.props;
    const { pdfDocument: discardedDocument, loadingTask: discardedLoadingTask } = this.state;
    this.setState({ pdfDocument: null, error: null, progress: 0 });

    if (typeof workerSrc === 'string') {
      GlobalWorkerOptions.workerSrc = workerSrc;
    }

    Promise.resolve()
      .then(() => {
        discardedLoadingTask && discardedLoadingTask.destroy();
        return discardedDocument && discardedDocument.destroy();
      })
      .then(() => {
        if (!url) {
          return;
        }

        const loadingTask = getDocument({
          ...this.props,
          ownerDocument,
          cMapUrl,
          cMapPacked,
        });

        this.setState({ loadingTask });

        loadingTask.onProgress = progressData => {
          const progress = +(progressData.loaded / progressData.total * 100).toFixed(2);
          this.props.onProgress?.(progress);
          this.setState({ progress });
        };


        return loadingTask.promise.then(pdfDocument => {
          this.props.onProgress?.(100);
          this.setState({ pdfDocument, progress: 100 });
        });
      })
      .catch(e => this.componentDidCatch(e));
  }

  getBeforeLoad = () => {
    const { beforeLoad } = this.props;
    const { progress } = this.state;
    if (typeof beforeLoad === 'function') {
      return beforeLoad(progress);
    }
    return beforeLoad;
  };

  render() {
    const { children } = this.props;
    const { pdfDocument, error } = this.state;
    return (
      <>
        <span ref={this.documentRef} />
        {error
          ? this.renderError()
          : !pdfDocument || !children
            ? this.getBeforeLoad()
            : children(pdfDocument)}
      </>
    );
  }

  renderError() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      return React.cloneElement(errorMessage, { error: this.state.error });
    }

    return null;
  }
}

export default Loader;
