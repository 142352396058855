import { Grid, Typography } from 'antd';
import styles from './Suggestion.module.css';
import React from 'react';
import IconFont from 'components/IconFont';

export type SuggestionProps = {
  tid?: string;
  suggestion: string;
  onClick?: (args: { suggestion: string, tid?: string }) => void;
  investment?: boolean;
};

function formatSuggestion(suggestion: string): string {
  const matches = suggestion.match(/\[(.*?)\]/g);

  if (!matches) {
    return suggestion;
  }

  for (let index = 0; index < matches.length; index++) {
    const match = matches[index];

    suggestion = suggestion.replaceAll(
      match,
      `<span class="${styles.placeholder}">${String.fromCharCode(65 + index)}${
        match.indexOf("'s") === -1 ? '' : "'s"
      }
</span>`,
    );
  }
  return suggestion;
}

export const Suggestion = ({ suggestion, tid, onClick, investment = false }: SuggestionProps) => {
  const [ show, setShow ] = React.useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const text = React.useRef<HTMLDivElement>(null);
  const overflow = text?.current?.scrollWidth > text?.current?.clientWidth;

  return (
    <div
      className={`${styles.container} suggestion ${investment && styles.investContainer}`}
      onClick={() => {
        onClick?.({ suggestion, tid });
      }}
      onMouseEnter={() => {
        screens.md && setShow(true);
      }}
      onMouseLeave={() => {
        screens.md && setShow(false);
      }}>
      <Typography.Text
        type="secondary"
        className={styles.suggestion}
        ref={text}>
        <span
          dangerouslySetInnerHTML={{ __html: formatSuggestion(suggestion) }} className={styles.suggestion}
        />
        {investment && (
          <IconFont type='flash' className={styles.flashIcon}/>
        )}
      </Typography.Text>
    </div>
  );
};
