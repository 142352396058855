import { PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import Error from 'components/Error';
import { envVars } from 'utils/envVars';
import { useNavigate } from 'react-router-dom';
import { useCommon } from 'store';
import { parse } from 'query-string';
import request from 'request';

type AuthErrorWrapperProps = PropsWithChildren<{}>;

const AuthErrorWrapper = ({ children }: AuthErrorWrapperProps) => {
  const { pageLoading, isAuthenticated, setIsAuthenticated, getUser, user } = useCommon();
  const params = parse(location.search);

  const {
    isLoading,
    error,
    logout,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  useLayoutEffect(() => {
    window.loginWithRedirect = loginWithRedirect;
    window.logout = logout;
    if (!params.error && !params.code && !isAuthenticated) {
      logout();
      loginWithRedirect();
    } else if (params.supportForgotPassword && params.supportSignUp) {
      logout();
      loginWithRedirect();
    } else {
      getUser();
    }
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && !isAuthenticated) {
      (async () => {
        const claims = await getIdTokenClaims();
        const accessToken = await getAccessTokenSilently();
        const { data } = await request.auth.getToken({
          id_token: claims?.__raw,
        });
        localStorage.setItem('iamToken', data);
        localStorage.setItem('accessToken', accessToken);
        getUser();
        setIsAuthenticated(true);
      })();
    }
  }, [ isLoading, isAuthenticated ]);

  if (params.error || error) {
    return (
      <Error msg={(params?.error_description as string) || error?.message} />
    );
  }

  if (!isAuthenticated || !user || pageLoading) return <Loading style={{ top: 0 }} />;

  return <>{children}</>;
};

export type AuthProviderProps = PropsWithChildren<{}>;

const getAuthorizationParams = () => {
  const authorizationParams = {
    redirect_uri: window.location.origin,
  };

  if (/^\/login|signup|signUp/.test(location.pathname)) {
    authorizationParams.authType = location.pathname.split('/')[1];
  }

  return authorizationParams;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const authorization = useRef(getAuthorizationParams());

  const onRedirectCallback = appState => {
    navigate(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname,
    );
  };

  const providerConfig = {
    domain: envVars.domain,
    clientId: envVars.clientId,
    audience: envVars.audience,
    onRedirectCallback,
    authorizationParams: authorization.current,
  };

  return (
    <Auth0Provider {...providerConfig}>
      <AuthErrorWrapper>{children}</AuthErrorWrapper>
    </Auth0Provider>
  );
};

export default AuthProvider;
