
export default {
  getBillingPortalUrl: {
    url: '/premium/subscription/billing_portal/v1',
  },
  getSettingsDisplay: {
    url: '/premium/setting/v1',
  },
  renew: {
    method: 'post',
    url: '/premium/subscription/renew/v1',
  },
  cancel: {
    method: 'post',
    url: '/premium/subscription/cancel/v1',
  },
};
