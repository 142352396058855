import React, { useState, useContext } from 'react';
import styles from './index.module.css';
import { Typography, Button, Image } from 'antd';
import request from 'request';
import { useRequest } from 'ahooks';
import { Management } from 'pages/Settings/types';
import IconFont from 'components/IconFont';
import { SubscriptionModalContext } from 'components/Layout';
import { useNavigate } from 'react-router-dom';
import ismobilejs from 'ismobilejs';
import Premium from './Premium';
import Trial from './Trial';
import { stringify } from 'query-string';
import moment from 'moment';
import { importGif } from 'utils';

const { Title } = Typography;

interface SettingProps {}

const Setting: React.FC<SettingProps> = () => {
  const navigate = useNavigate();
  const isMobile = ismobilejs(window.navigator).any;
  const [ isLoading, setIsLoading ] = useState(false);
  const subscriptionModal = useContext(SubscriptionModalContext);

  const { data = {} as Management, loading } = useRequest<Management, any>(() =>
    request.premium.getSettingsDisplay().then(res => res.data),
  );

  const onSubscription = async () => {
    const newWindow: any = isMobile ? window.open() : null;
    try {
      setIsLoading(true);
      const res = await request.premium.getBillingPortalUrl();
      if (isMobile) {
        newWindow.location = res.data[0]?.url;
      } else {
        window.open(res.data[0]?.url, '_blank');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onHander = type => {
    const params = {
      price: data.price,
      expired_at: data.subscription_expired_at,
      period: data.subscription_period,
      billing_period: data.billing_period,
      tier:
        data.subscription_tier.charAt(0).toUpperCase() +
        data.subscription_tier.slice(1),
    };
    navigate(`/settings/${type}?${stringify(params)}`);
  };

  // 订阅过期
  const isSubscriptionExpired =
    /free/.test(data.subscription_tier) && data.new_user === 0;
  // 订阅者
  const isPremium = /basic|premium/.test(data.subscription_tier);

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loading}>
          <Image
            src={importGif('tree')}
            preview={false}
            style={{ width: 80 }}
          />
          <span className={styles.loadingText}>Loading</span>
        </div>
      )}
      {
        !loading && <div className={styles.body} >
          <Title className={styles.title}>Settings</Title>
          <div className={styles.management}>Subscription Management</div>
          {isPremium ? (
            <Premium data={data} />
          ) : (
            !isSubscriptionExpired && <Trial data={data} />
          )}

          {isSubscriptionExpired ? (
            <div className={styles.cancelPlan}>
              <div className={styles.left}>
                <div>Current Plan</div>
                <span>No active plan</span>
              </div>
              <Button
                className={styles.choosePlan}
                onClick={() => subscriptionModal.setIsOpen(true)}>
              Choose Plan
              </Button>
            </div>
          ) : (
            <div className={styles.planDetails}>
              <div className={styles.left}>Plan Details / Payment Methods</div>
              <Button
                className={styles.btn}
                onClick={onSubscription}
                loading={isLoading}
                icon={<IconFont type="icons1" style={{ fontSize: 20 }} />}>
              Manage on Stripe
              </Button>
            </div>
          )}
          {/* 订阅被取消，但是还在订阅有效期中 */}
          {isPremium &&
          (data?.to_cancel === 1 ? (
            <div className={styles.cancelPlan}>
              <div className={styles.left}>
                <div>Renew Plan</div>
                <span className={styles.date}>
                  Select this option to automatically renew your current plan on{' '}
                  {data?.subscription_expired_at && (
                    <span>
                      {' '}
                      {moment
                        .unix(data?.subscription_expired_at)
                        .format('MMMM D, YYYY')}
                      .
                    </span>
                  )}
                </span>
              </div>
              <Button className={styles.btn} onClick={() => onHander('renew')}>
                Renew Plan
              </Button>
            </div>
          ) : (
            <div className={styles.cancelPlan}>
              <div className={styles.left}>
                <div>Cancel Plan</div>
                <span className={styles.date}>
                  Retain access until current billing period ends. Subscription
                  won't auto-renew.
                </span>
              </div>
              <Button className={styles.btn} onClick={() => onHander('cancel')}>
                Cancel Plan
              </Button>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default Setting;
