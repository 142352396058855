import React, { FC } from 'react';
import Routes from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ConfigProvider, Result, Button } from 'antd';
import { ProviderApi } from 'react-axios-http';
import enUS from 'antd/es/locale/en_US';
import apis from 'request';
import AuthProvider from './AuthProvider';

const ErrorFallback = () => {
  return (
    <Result
      title={'React Error Boundary'}
      subTitle={'Ooops, something went wrong :( '}
      extra={
        <Button onClick={() => window.location.assign(window.location.origin)}>
          Refresh
        </Button>
      }
    />
  );
};

const App: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConfigProvider locale={enUS}>
        <BrowserRouter>
          <AuthProvider>
            <ProviderApi apis={apis as unknown as Record<string, unknown>}>
              <Routes />
            </ProviderApi>
          </AuthProvider>
        </BrowserRouter>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
