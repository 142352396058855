export const envVars = {
  portageurBaseUrl: import.meta.env.VITE_PORTAGEUR_BASE_URL as string,
  portageurApiKey: import.meta.env.VITE_PORTAGEUR_API_KEY as string,

  dummyEmail: import.meta.env.VITE_DUMMY_EMAIL as string,
  dummyPassword: import.meta.env.VITE_DUMMY_PASSWORD as string,

  domain: import.meta.env.VITE_PORTAGEUR_AUTH0_DOMAIN as string,
  clientId: import.meta.env.VITE_PORTAGEUR_AUTH0_CLIENT_ID as string,
  audience: import.meta.env.VITE_PORTAGEUR_AUTH0_AUDIENCE as string,

  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
  stripePricingTableId: import.meta.env.VITE_STRIPE_PRICING_TABLE_ID as string,
};
