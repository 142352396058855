import React, { useContext } from 'react';
import { Button } from 'antd';
import { Management } from 'pages/Settings/types';
import styles from './index.module.css';
import moment from 'moment';
import { SubscriptionModalContext } from 'components/Layout';

interface PremiumProps {
  data: Management;
}

const Trial: React.FC<PremiumProps> = ({ data }) => {
  const isExpired = data?.subscription_tier === 'free';
  const subscriptionModal = useContext(SubscriptionModalContext);

  return (
    <>
      <div className={styles.planTitle}>Current Plan</div>
      <div className={styles.plan}>
        {isExpired ? (
          <div className={styles.planExpired}>
            <div className={styles.trialEneded}>Trial ended</div>
            <Button className={styles.button} onClick={() => subscriptionModal.setIsOpen(true)}>Choose Plan</Button>
          </div>
        ) : (
          <div className={styles.planPremium}>
            <div className={styles.planPremium_1}>
              <div className={styles.planPremium_1_1}>
                <span className={styles.trial}>Trial</span>
                {
                  data?.subscription_days_left_display &&
              <div className={styles.period}>({data?.subscription_days_left_display})</div>
                }
              </div>
            </div>
            {/* <div className={styles.planPremium_2}>
            <span className={styles.days_left}>
              {data?.subscription_days_left}
            </span>
            <span> of 400 requests left</span>
          </div>
          <Progress
            percent={(data?.subscription_days_left / 400) * 100}
            trailColor="#B6B7BF"
            strokeColor="#F79329"
            showInfo={false}
            strokeWidth={4}
            className={styles.progress}
          /> */}
          </div>
        )}
        {
          !isExpired &&
        <div className={styles.planDate}>
          <div className={styles.planNextPayment}>
            <div className={styles.planDate_1}>
              {isExpired ? 'Expired' : 'Trial Expires'}
            </div>
            <div className={styles.planDate_2}>
              {data?.subscription_expired_at && moment.unix(data?.subscription_expired_at).format('MMMM D, YYYY')}
            </div>
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default Trial;
