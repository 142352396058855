import moment from 'moment';

/**
 *
 * @param {string} name
 * @param {string} ext
 */
export const importImg = (name: string, ext = 'png') => {
  try {
    return new URL(`../assets/images/${name}.${ext}`, import.meta.url).href;
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return '';
};

/**
 *
 * @param {string} name
 */
export const importGif = (name: string) => {
  try {
    return new URL(`../assets/gifs/${name}.gif`, import.meta.url).href;
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return '';
};

export const getCssVar = (name: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
};

export const formatDate = (date: Date | string | number) => {
  return moment(date).format('yyyy/MM/DD');
};

export function getRandomElements<T>(arr: T[], amount: number): T[] {
  const computedAmount = Math.min(amount, arr.length);

  const result: T[] = [];
  const usedIndexes: Set<number> = new Set();

  while (result.length < computedAmount) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    if (!usedIndexes.has(randomIndex)) {
      result.push(arr[randomIndex]);
      usedIndexes.add(randomIndex);
    }
  }

  return result;
}

export function getRandomNumberInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function calculateDaysBetweenDates(date1: Date, date2: Date) {
  const a = moment(date1);
  const b = moment(date2);
  return a.diff(b, 'days');
}
