import React from 'react';
import styles from './index.module.css';
import { parse } from 'query-string';
import { importImg } from 'utils';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface TipsProps {}

const Tips: React.FC<TipsProps> = () => {
  const params = parse(window.location.search);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img src={importImg('logo-new')} width={170} />
      <div className={styles.title}>{params.title}</div>
      {params.description && (
        <div className={styles.description}>
          {params.description}
          {params.date && <span> {moment.unix(+params.date).format('MMMM D, YYYY')}</span>}.
        </div>
      )}
      <Button
        className={styles.button}
        onClick={() => navigate('/settings/management')}>
        Back
      </Button>
    </div>
  );
};

export default Tips;
