/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import styles from './index.module.css';

export type MarkdownRendererProps = {
  markdown: string;
  tableWrapperStyle?: React.CSSProperties;
};

const MarkdownRenderer = ({
  markdown,
  tableWrapperStyle,
}: MarkdownRendererProps) => {
  return (
    <Markdown
      className={styles.markdown}
      remarkPlugins={[ remarkGfm ]}
      rehypePlugins={[ rehypeRaw ]}
      components={{
        p: ({ node, ...props }) => <Typography.Text {...(props as any)} />,
        h1: ({ node, ...props }) => <Typography.Title {...(props as any)} />,
        h2: ({ node, ...props }) => <Typography.Title level={2} {...(props as any)} />,
        h3: ({ node, ...props }) => <Typography.Title level={3} {...(props as any)} />,
        h4: ({ node, ...props }) => <Typography.Title level={4} {...(props as any)} />,
        h5: ({ node, ...props }) => <Typography.Title level={5} {...(props as any)} />,
        h6: ({ node, ...props }) => <Typography.Title level={5} {...(props as any)} />,
        a: ({ node, ...props }) => <Link {...(props as any)} target="_blank" />,
        table: ({ node, ...props }) => (
          <div style={tableWrapperStyle} className={styles.tableWrapper}>
            <table {...props} />
          </div>
        ),
      }}>
      {markdown}
    </Markdown>
  );
};

export default MarkdownRenderer;
