import React from 'react';
// import { Link } from 'react-router-dom';
import styles from './index.module.css';
// import { Typography } from 'antd';

const Footnote = ({ bottom }) => {
  return (
    <div className={styles.footer} style={{ bottom }}>
        Chatbot responses are not investment advice. Accuracy, correctness or appropriateness cannot be guaranteed.
      {/* <div>*/}
      {/*  <Link to="https://test-portageur.portageb.com/law/terms.html" target="_blank" >*/}
      {/*    <Typography.Text style={{ fontSize: 12 }} underline>*/}
      {/*                      Terms of Use*/}
      {/*    </Typography.Text>*/}
      {/*  </Link>*/}
      {/*  {' '}|{' '}*/}
      {/*  <Link to="https://test-portageur.portageb.com/law/privacy.html" target="_blank">*/}
      {/*    <Typography.Text*/}
      {/*      style={{ fontSize: 12 }}*/}
      {/*      underline*/}
      {/*    >*/}
      {/*                      Privacy Policy*/}
      {/*    </Typography.Text>*/}
      {/*  </Link>*/}
      {/* </div>*/}
    </div>
  );
};

export default Footnote;
