import classNames from 'classnames';
import { Suggestion, SuggestionProps } from './Suggestion';
import styles from './index.module.css';

export type SuggestionListProps = {
  suggestions: SuggestionProps[];
  className?: string;
  onSuggestionClick?: (args: { suggestion: string, tid?: string }) => void;
};

const SuggestionList = ({
  suggestions,
  className,
  onSuggestionClick,
}: SuggestionListProps) => {

  return (
    <div className={classNames(styles.container, className)}>
      {suggestions.map(suggestion => (
        <Suggestion
          key={suggestion.suggestion}
          onClick={onSuggestionClick}
          investment = {!!suggestion.tid}
          {...suggestion}
        />
      ))}
    </div>
  );
};

export default SuggestionList;
