import React, { useRef } from 'react';
import streaming from 'request/apis/streaming';
import { ChatStatus } from 'request/apis/portageurTypes';
import MarkdownRenderer from 'components/MarkdownRenderer';
import { Message, MessageSender } from 'components/MessagesRenderer/types';
import portageurApi from 'request/apis/portageur';

interface UseStreamProps {
  getStream: () => void;
}

interface StreamProps {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setIsPooling: (isPooling: boolean) => void;
  chatQuery: any;
  currentRequestId: React.MutableRefObject<number>;
  sessionId: React.MutableRefObject<string | null>;
  conversationId: React.MutableRefObject<string | null>;
  isInvestment: boolean;
}

const UseStream = ({
  setMessages,
  setIsPooling,
  chatQuery,
  currentRequestId,
  sessionId,
  conversationId,
  isInvestment,
}: StreamProps): UseStreamProps => {
  const streamRef = useRef('');

  const getQuestion = async () => {
    const res = await portageurApi[isInvestment ? 'getInvestmentQuestion' : 'getQuestion']({
      sessionId: sessionId.current || undefined,
      requestId: currentRequestId.current,
      conversationId: conversationId.current || undefined,
    });

    if (res?.data?.type !== 'answer') {
      throw new Error('Something went wrong. Please try again.');
    }

    currentRequestId.current = res?.data.request_id;

    return res.data;
  };

  const onMessage = answer => {
    streamRef.current = `${streamRef.current}${answer}`;
    const text = streamRef.current;

    const getNewMessage = () => {
      const newMessage: Message = {
        requestId: currentRequestId.current,
        message: text,
        html: <MarkdownRenderer markdown={text} />,
        status: 'typeWriter',
        sender: 'bot' as MessageSender,
      };

      return newMessage;
    };

    setMessages(prev => {
      const _prev = prev.filter(item => item.status !== 'progress');
      const newMessage = getNewMessage();
      const lastItem = _prev[_prev.length - 1];
      if (lastItem.status === 'typeWriter') {
        return [ ..._prev.slice(0, prev.length - 1), newMessage ];
      }
      return [ ..._prev, newMessage ];
    });
  };

  const onClose = async msg => {
    streamRef.current = '';

    setMessages(prev => {
      const _prev = [ ...prev ];
      const lastItem = _prev[_prev.length - 1];
      if (lastItem.status === 'typeWriter') {
        if (lastItem.message) {
          const text = isInvestment ? lastItem.message : JSON.stringify(lastItem.message)
            .replace(/\\\\n/g, '\n')
            .replace(/^"|"$/g, '');
          lastItem.status = 'answer';
          lastItem.message = text;
          lastItem.html = <MarkdownRenderer markdown={text} />;
        } else {
          _prev.pop();
        }
      }
      if (lastItem.status === 'progress') {
        _prev.pop();
      }
      return _prev;
    });

    console.log('SSE Close ===> ' + msg);

    if (msg === 'message_end') {
      await chatQuery.runAsync(getQuestion());
    }

    setIsPooling(false);
  };

  const onError = (msg, msgType) => {
    streamRef.current = '';
    const isLimit = msgType === 'message_limit';

    if (isLimit) {
      sessionId.current = null;
      conversationId.current = null;
      currentRequestId.current = -1;
    }

    const newMessage = {
      message: msg,
      status: isLimit ? 'error' : 'retry',
      sender: 'bot' as MessageSender,
    } as Message;

    setMessages(prev => [
      ...prev.filter(item => item.status !== 'progress'),
      newMessage,
    ]);

    setIsPooling(false);
    console.log('SSE ERROR ===> ' + msg);
  };

  const getStream = async () => {
    if (!conversationId.current || !sessionId.current) return;

    const params = {
      requestId: currentRequestId.current,
      sessionId: sessionId.current,
      conversationId: conversationId.current,
    };

    await streaming({
      isInvestment,
      params,
      onOpen() {
        streamRef.current = '';
        setIsPooling(true);
        console.log('SSE Open');
      },
      onMessage,
      onClose,
      onError,
    });
  };

  return {
    getStream,
  };
};

export default UseStream;
