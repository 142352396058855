import React from 'react';
import styles from './index.module.css';
import { Typography, Radio, Space, Input, Button, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import IconFont from 'components/IconFont';
import request from 'request';
import { parse, stringify } from 'query-string';
import moment from 'moment';
import { FEEDBACK_OPTIONS } from 'pages/Settings/config';

const { Title } = Typography;

interface CancelProps {}

const Cancel: React.FC<CancelProps> = () => {
  const params = parse(window.location.search);
  const navigate = useNavigate();

  const [ value, setValue ] = React.useState('');
  const [ comment, setComment ] = React.useState('');
  const [ alert, setAlert ] = React.useState('');
  const [ loading, setLoading ] = React.useState(false);

  const url = window.location.href;

  const link = /test/.test(url)
    ? 'test-portageur.portageb.com'
    : /beta/.test(url)
      ? 'beta-portageur.portageb.com'
      : 'app.portageur.ai';

  const onCancelPlan = async () => {
    try {
      setLoading(true);
      const { code, msg } = await request.premium.cancel({
        cancellation_feedback: value,
        cancellation_comment: comment,
      });
      setLoading(false);
      if (code === 200) {
        const search = {
          title: 'Cancellation Confirmed',
          description: `You\'ll have access to ${params.tier} until`,
          date: params.expired_at,
          type: 'success',
        };
        navigate(`/settings/tips?${stringify(search)}`);
      } else {
        setAlert(msg);
      }
    } catch (error) {
      setLoading(false);
      setAlert('An error occurred, please try again later.');
    }
  };

  return (
    <div className={styles.container}>
      <Title className={styles.title}>Settings</Title>
      <div className={styles.management}>Cancel Your Current Plan</div>
      <div className={styles.plan}>
        <div className={styles.top}>
          <div className={styles.top_1}>{params.tier}</div>
          <div className={styles.top_2}></div>
          <div className={styles.top_3}>
            <span>US${params.price}</span>
            <span className={styles.symbol}>{params.billing_period}</span>
          </div>
        </div>
        <div className={styles.bottom}>{params.period}</div>
      </div>
      <div className={styles.info}>
        <p>
          Your plan will be canceled, but can still be used until the end of
          your billing period on{' '}
          {params?.expired_at && (
            <span>
              {moment.unix(+params?.expired_at).format('MMMM D, YYYY')}
            </span>
          )}
          .
        </p>
        <p>If you change your mind, you can reactivate your subscription.</p>
      </div>
      <div className={styles.management}>Feedback</div>
      <div className={styles.feedbackNote}>
        Let us know why you are not renewing
      </div>
      <Radio.Group
        className={styles.radioGroup}
        onChange={e => {
          setValue(e.target.value);
        }}
        value={value}>
        <Space direction="vertical" size={16}>
          {FEEDBACK_OPTIONS.filter(v => v.show).map(v => (
            <Radio key={v.value} value={v.value}>
              {v.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Input.TextArea
        className={styles.textArea}
        value={comment}
        onChange={e => {
          setComment(e.target.value);
        }}
        placeholder="Tell us why? (optional)"
      />
      <div className={styles.policy}>
        By canceling your plan, you agree to Portageur’s{' '}
        <a href={`https://${link}/auth0/law/terms.html`} target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={`https://${link}/auth0/law/privacy.html`} target="_blank">
          Privacy Policy
        </a>
        .
      </div>
      {alert && (
        <Alert
          icon={<IconFont type="alert" style={{ fontSize: 32 }} />}
          message="Cancellation Failed"
          description={alert}
          type="error"
          showIcon={true}
          className={styles.alert}
        />
      )}
      <div className={styles.footer}>
        <Button
          className={styles.button}
          onClick={() => {
            navigate('/settings/management');
          }}
        >
          Go Back
        </Button>
        <Button
          className={styles.button}
          onClick={onCancelPlan}
          loading={loading}
        >
          Cancel Plan
        </Button>
      </div>
    </div>
  );
};

export default Cancel;
