import React from 'react';
import { Tooltip } from 'antd';
import { Management } from 'pages/Settings/types';
import styles from './index.module.css';
import moment from 'moment';
import IconFont from 'components/IconFont';

interface PremiumProps {
  data: Management;
}

const Premium: React.FC<PremiumProps> = ({ data }) => {
  return (
    <>
      <div className={styles.planTitle}>Current Plan</div>
      <div className={styles.plan}>
        <div className={styles.planPremium}>
          <div className={styles.planPremium_1}>
            <div className={styles.planPremium_1_1}>
              <div className={styles.tier}>
                {data?.subscription_tier?.charAt(0)?.toUpperCase() + data?.subscription_tier?.slice(1)}
              </div>
              <div className={styles.period}>{data?.subscription_period}</div>
            </div>
            <div className={styles.planPremium_1_2}>
              <div className={styles.price}>
              US${data?.price}
              </div>
              <div className={styles.period}>{data?.billing_period}
              </div>
            </div>
          </div>
          {/* <div className={styles.planPremium_2}>
          <span className={styles.days_left}>
            {data?.subscription_days_left}
          </span>
          <span> of 400 requests left</span>
        </div> */}
          {/* <Progress
          percent={(data?.subscription_days_left / 400) * 100}
          trailColor="#B6B7BF"
          strokeColor="#F79329"
          showInfo={false}
          strokeWidth={4}
          className={styles.progress}
        /> */}
        </div>
        <div className={styles.planDate}>
          {
            data?.next_billing_at &&
              <div className={styles.planNextPayment}>
                <div className={styles.planDate_1}>Next Payment</div>
                <div className={styles.planDate_2} style={{ marginBottom: 40 }}>
                  {moment.unix(data?.next_billing_at).format('MMMM D, YYYY')}
                  {
                    data.show_tooltip === 1 &&
                      <Tooltip
                        overlayClassName={styles.tooltip}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        title={
                          <div>
                            <div className={styles.tooltipTitle}>Yearly Plan</div>
                            <div className={styles.tooltipContent}>
                            This payment covers your next month & fulfills your annual
                            commitment.
                            </div>
                          </div>
                        }>
                        <IconFont type="icons2" style={{ marginLeft: 8 }} />
                      </Tooltip>
                  }
                </div>
              </div>
          }
          <div className={styles.planRenewed}>
            <div className={styles.planDate_1}>
              {data?.to_cancel !== 1
                ? 'Plan will be renewed automatically'
                : 'Plan Expires'}
            </div>
            <div className={styles.planDate_2}>
              {data?.subscription_expired_at && moment.unix(data?.subscription_expired_at).format('MMMM D, YYYY')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Premium;
