import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { importImg } from 'utils';
import styles from './index.module.css';

export default function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/library-chat');
    }, 3000);
  }, []);

  return (
    <div className={styles.notFound}>
      <Link to="/library-chat">
        <img src={importImg('pb-logo')} className={styles.logo} />
      </Link>
      <div className={styles.body}>
        <div>
          <img src={importImg('not-found')} width={301} />
        </div>
        <div className={styles.title}>Page Not Found</div>
        <div className={styles.whoops}>
          The page you are trying to access has moved or no longer exists.
          <br /> You will be automatically redirected to the home page.
        </div>
        <div className={styles.content}></div>
      </div>
    </div>
  );
}
