import { Dropdown, Typography } from 'antd';
import styles from './index.module.css';
import classNames from 'classnames';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import IconFont from 'components/IconFont';
import { SubscriptionTier } from 'request/apis/portageurTypes';
import { theme } from '../../theme';
import { importImg } from 'utils';
import UpgradeToPremium from 'components/UpgradeToPremium';
import ismobilejs from 'ismobilejs';
import { useNavigate } from 'react-router-dom';
import { SidebarContext } from 'components/Layout';

export type ProfileMenuProps = {
  username: string;
  profilePicture?: string;
  className?: string;
  collapsed?: boolean;
  showText?: boolean;
  subscriptionTier?: SubscriptionTier;
  subscriptionDaysLeft?: number;
  subscription_days_left_display: string;
};

const subscriptionTierLabels: Record<
Exclude<SubscriptionTier, null>,
string
> = {
  premium: 'Premium',
  trial: 'Trial',
  free: '',
  basic: 'Basic',
};

const ProfileMenu = ({
  username,
  className,
  // profilePicture,
  collapsed,
  showText,
  // subscriptionDaysLeft,
  subscriptionTier,
  subscription_days_left_display,
}: ProfileMenuProps) => {
  const { logout } = useAuth0();
  const iconRef = useRef<HTMLSpanElement>(null);
  const [ menuOpen, setMenuOpen ] = useState(false);
  const isMobile = ismobilejs(window.navigator).any;
  const navigate = useNavigate();
  const sidebar = useContext(SidebarContext);

  const onLogout = () => {
    localStorage.clear();
    logout();
    // loginWithRedirect();
  };

  useEffect(() => {
    if (collapsed) {
      setMenuOpen(false);
    }
  }, [ collapsed ]);

  useEffect(() => {
    if (menuOpen) {
      iconRef.current?.style.setProperty('transform', 'rotate(180deg)');
    } else {
      iconRef.current?.style.setProperty('transform', 'rotate(0deg)');
    }
  }, [ menuOpen ]);

  const items = [
    {
      label: collapsed ? '' : 'Settings',
      key: 'Settings',
      icon: <IconFont type="setting" style={{ fontSize: 24 }} />,
      onClick: () => {
        setMenuOpen(false);
        navigate('/settings');
        setTimeout(() => {
          sidebar.setIsOpen(false);
        }, 300);
      },
      className: styles.menuItem,
    },
    {
      label: collapsed ? '' : 'Log Out',
      key: 'logout',
      icon: <IconFont type="out" style={{ fontSize: 24 }} />,
      onClick: onLogout,
      className: styles.menuItem,
    },
  ];

  return (
    <div className={classNames(className)}>
      <UpgradeToPremium />
      <Dropdown
        menu={{ items: collapsed ? [] : items, className: styles.menu }}
        placement="top"
        open={menuOpen}
        trigger={[ 'click' ]}
        align={{ offset: [ 0, 1 ] }}
        onOpenChange={open => {
          setMenuOpen(open);
        }}
        overlayStyle={{ minWidth: collapsed ? 0 : 241 }}
      >
        <div
          className={classNames(
            styles.button,
            collapsed && styles.buttonCollapsed,
            menuOpen && styles.menuOpen,
          )}>
          <div className={styles.userInfo}>
            {/* <Image*/}
            {/*  preview={false}*/}
            {/*  src={profilePicture}*/}
            {/*  className={styles.profilePicture}*/}
            {/*/ >*/}
            {/* <IconFont type="user-icon" style={{ fontSize: 32, color: theme.colors.gray }} />*/}
            <img src={importImg('userIcon')} />
            {showText && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <Typography.Text
                  ellipsis={true}
                  style={{ width: isMobile ? 250 : 150, paddingRight: 20 }}
                  className={classNames(
                    styles.username,
                    !showText && styles.hideText,
                  )}>
                  {username}
                </Typography.Text>
                {subscriptionTier !== 'free' && (
                  <div>
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        color: 'rgb(182, 183, 191)',
                        fontWeight: 500,
                        lineHeight: 1,
                      }}>
                      {subscriptionTier === 'trial'
                        ? subscription_days_left_display
                        : subscriptionTierLabels[subscriptionTier]}
                    </Typography.Text>
                  </div>
                )}
              </div>
            )}
          </div>

          <IconFont
            type="down"
            ref={iconRef}
            style={{
              fontSize: 24,
              transition: 'transform 0.3s',
              color: theme.colors.gray,
            }}
            className={classNames(!showText && styles.hideText)}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileMenu;
