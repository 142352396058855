import classNames from 'classnames';
import { Message } from './types';
import styles from './UserMessage.module.css';
import { Typography } from 'antd';

export type UserMessageProps = Message & {
  className?: string;
};

export const UserMessage = ({ html, className, message }: UserMessageProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Typography.Text>{html || message}</Typography.Text>
    </div>
  );
};
