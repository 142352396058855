import { combineApi } from 'react-axios-http';

import auth from './auth';
import premium from './premium';

type FetchFunc = (params?: any) => Promise<any>;

type Handler<T> = {
  [K in keyof T]: FetchFunc;
};

export interface Apis {
  auth: Handler<typeof auth>;
  premium: Handler<typeof premium>;
}

const apis = combineApi({ auth, premium });

export default apis;
