import type { SerializedParagraphNode, Spread } from 'lexical';

import {
  $applyNodeReplacement,
  $isTextNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalNode,
  NodeKey,
  ParagraphNode,
  SerializedTextNode,
  TextNode,
} from 'lexical';
import { type } from 'os';

export type PlaceholderType = 'company' | 'sector' | 'industry';

export type SerializedPlaceholderNode = Spread<
{
  placeholderName: string;
  type: 'placeholder';
  id: string;
  placeholderType: PlaceholderType;
},
SerializedParagraphNode
>;

function convertPlaceholderElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createPlaceholderNode(textContent, '', 'company');
    return {
      node,
    };
  }

  return null;
}

const placeholderStyle = 'font-weight: bold';
const className = 'placeholder';

export class PlaceholderNode extends ElementNode {
  __placeholder: string;
  __id: string;
  __placeholderType: PlaceholderType;
  __canBeEmpty: boolean;

  static getType(): string {
    return 'placeholder';
  }

  static getPlaceholderTypeFromString(string: string): PlaceholderType | null {
    if (string.includes('Company')) {
      return 'company';
    } else if (string.includes('Sector')) {
      return 'sector';
    } else if (string.includes('Industry')) {
      return 'industry';
    }

    return null;
  }

  static clone(node: PlaceholderNode): PlaceholderNode {
    return new PlaceholderNode(
      node.__placeholder,
      node.__id,
      node.__placeholderType,
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedPlaceholderNode): ElementNode {
    const node = $createPlaceholderNode(
      serializedNode.placeholderName,
      serializedNode.id,
      serializedNode.placeholderType,
    );

    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON(): SerializedPlaceholderNode {
    return {
      ...super.exportJSON(),
      placeholderName: this.__placeholder,
      type: 'placeholder',
      version: 1,
      id: this.__id,
      placeholderType: this.__placeholderType,
    };
  }

  constructor(
    placeholderName: string,
    id: string,
    type: PlaceholderType,
    key?: NodeKey,
  ) {
    super(key);
    this.__placeholder = placeholderName;
    this.__id = id;
    this.__placeholderType = type;
    this.__canBeEmpty = false;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('span');
    dom.setAttribute('data-lexical-placeholder', 'true');
    dom.setAttribute('data-lexical-placeholder-id', this.__id);
    dom.setAttribute('data-lexical-placeholder-id', this.__id);
    dom.classList.add(className);
    dom.style.cssText = placeholderStyle;
    dom.className = 'placeholder';
    return dom;
  }

  canBeEmpty(): boolean {
    return this.__canBeEmpty;
  }

  updateDOM(
    prevNode: PlaceholderNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    return false;
  }

  isSegmented(): false {
    return false;
  }

  isToken(): true {
    return true;
  }
}

export function $createPlaceholderNode(
  placeholderName: string,
  id: string,
  type: PlaceholderType,
): PlaceholderNode {
  return $applyNodeReplacement(new PlaceholderNode(placeholderName, id, type));
}

export function $isPlaceholderNode(
  node: LexicalNode | null | undefined,
): node is PlaceholderNode {
  return node instanceof PlaceholderNode;
}
