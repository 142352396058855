import React, { useRef } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { Spin } from 'antd';
import _ from 'lodash';
import { importImg } from 'utils';
import { ChatContext } from 'components/Chat/useContext';
import ismobilejs from 'ismobilejs';
import PC from './PC';
import Mobile from './Mobile';

interface QrankProps {
  companies: string[];
  className?: string;
}

const Qrank: React.FC<QrankProps> = ({ className }) => {
  const { qrank } = React.useContext(ChatContext);
  const { data, loading } = qrank;
  const isMobile = ismobilejs(window.navigator).any;
  const qrankRef = useRef(null);

  const formatVal = val => {
    if (data?.show_detail) return _.isNull(val) ? '--' : val;
    return <img src={importImg('mask')} />;
  };

  return (
    <div className={classNames(styles.qrank, className)} ref={qrankRef}>
      <h5 className={styles.title}>
          While I’m working on your request, here are the company’s Sustainability Scores and the company practices that may impact its financial resilience.
      </h5>

      <div className={styles.body}>
        <Spin spinning={loading} className={styles.spin}>
          {(isMobile || qrankRef?.current?.offsetWidth < 670) ? (
            <Mobile data={data} formatVal={formatVal} />
          ) : (
            <PC data={data} formatVal={formatVal} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default Qrank;
