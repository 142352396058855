import React, { useContext } from 'react';
import styles from './index.module.css';
import IconFont from '../../IconFont/index';
import { Tooltip } from 'antd';
import { useCommon } from 'store/index';
import { SubscriptionModalContext } from 'components/Layout';
import gTag from 'utils/gTag';
import request from 'request';
import ismobilejs from 'ismobilejs';

const HintTooltip: React.FC = () => {
  const { user } = useCommon();
  const isMobile = ismobilejs(window.navigator).any;

  const { subscription } = user;

  const onOpenPortal = async () => {
    const newWindow: any = isMobile ? window.open() : null;
    try {
      // setIsLoading(true);
      const res = await request.premium.getBillingPortalUrl();
      if (isMobile) {
        newWindow.location = res.data[0]?.url;
      } else {
        window.open(res.data[0]?.url, '_blank');
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const content =
    subscription?.tier === 'premium' || subscription?.tier === 'trial' ? (
      <span>
        Get detailed Sustainability Scores and much more with a subscription to
        Sustainability Copilot{' '}
        <a
          href="https://www.portageb.com/?utm_source=referral&utm_medium=portageur&utm_campaign=product&utm_content=qrank"
          target="_blank"
          className={styles.link}
          style={{ fontSize: 12 }}
          onClick={() => gTag.clickButton('pb')}
        >
          HERE
        </a>
        .
      </span>
    ) : (
      <span>
        Go Premium now <br /> to get detailed <br /> Sustainability Scores.{' '}
        <br /> Save time and make
        <br />
        smarter decision. <br />
        <br />
        <a
          className={styles.link}
          onClick={() => {
            onOpenPortal();
            gTag.clickButton('upgrade');
          }}
        >
          Upgrade
        </a>
      </span>
    );

  return (
    <Tooltip
      title={content}
      overlayInnerStyle={{
        backgroundColor: '#424854',
        fontSize: 12,
        padding: '12px 16px',
        lineHeight: '1.4',
      }}
    >
      <IconFont
        type="icons"
        style={{ fontSize: 16, margin: '2px 5px 0 2px' }}
      />
    </Tooltip>
  );
};

export default HintTooltip;
