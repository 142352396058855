import { MenuLinks } from './interface';
import IconFont from 'components/IconFont';
import { theme } from '../theme';

export const menuLinks: MenuLinks = [
  {
    name: 'Assistants',
    links: [
      {
        orange: false,
        path: '/library-chat',
        name: 'Librarian',
        availableAtSubscription: [ 'premium', 'trial', 'basic' ],
        icon: () => (
          <IconFont
            type="librarian"
            style={{ fontSize: 24, color: theme.colors.iconGray }}
          />
        ),
        activeIcon: () => (
          <IconFont type="librarian" style={{ fontSize: 24 }} />
        ),
        disabledIcon: () => (
          <IconFont
            type="librarian"
            style={{ fontSize: 24, color: '#808194' }}
          />
        ),
      },
      {
        path: '/snapshot',
        name: 'Recon',
        comingSoon: true,
        availableAtSubscription: [ 'premium', 'basic' ],
        disabled: true,
        icon: () => (
          <IconFont
            type="binocular"
            style={{ fontSize: 24, color: theme.colors.iconGray }}
          />
        ),
        activeIcon: () => (
          <IconFont type="binocular" style={{ fontSize: 24 }} />
        ),
        disabledIcon: () => (
          <IconFont
            type="binocular"
            style={{ fontSize: 24, color: '#808194' }}
          />
        ),
      },
    ],
  },
  {
    name: 'Repository',
    links: [
      {
        path: '/chat-history',
        availableAtSubscription: [ 'premium', 'trial', 'basic' ],
        name: 'History',
        icon: () => (
          <IconFont
            type="history"
            style={{ fontSize: 24, color: theme.colors.iconGray }}
          />
        ),
        activeIcon: () => <IconFont type="history" style={{ fontSize: 24 }} />,
        disabledIcon: () => (
          <IconFont type="history" style={{ fontSize: 24, color: '#808194' }} />
        ),
      },
    ],
  },
];
