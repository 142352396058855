import React, { Suspense } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import NotFound from 'components/NotFound';
import Loading from 'components/Loading';
import Layout from 'components/Layout';
import { useCommon } from 'store';

// import Home from 'pages/Home';
import Reports from 'pages/ChatHistory';
import LibraryChat from 'pages/LibraryChat/routes';
import SettingsRoutes from 'pages/Settings';
import Login from 'pages/Login';
import { PrivateRouteWrapper } from 'components/PrivateRouteWrapper';
import ChatHistory from 'pages/ChatHistory/routes';
import ChartHistoryDetails from 'pages/ChatHistory/routes/details';
import { SubscriptionRouteWrapper } from 'components/SubscriptionRouteWrapper';

const App = () => {
  const { loading } = useCommon();

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        {loading ? <Loading style={{ left: 116 }} /> : <Outlet />}
      </Suspense>
    </Layout>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <PrivateRouteWrapper>
            <Navigate to="library-chat" />
          </PrivateRouteWrapper>
        ),
      },

      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'signup',
        element: <Login />,
      },
      {
        path: 'signUp',
        element: <Login />,
      },

      // {
      //   path: "home",
      //   element: (
      //     <PrivateRouteWrapper>
      //       <Home />
      //     </PrivateRouteWrapper>
      //   ),
      // },
      {
        path: 'library-chat',
        element: (
          <PrivateRouteWrapper>
            {/* <SubscriptionRouteWrapper availableAtSubscription={["premium"]}> */}
            <LibraryChat />
            {/* </SubscriptionRouteWrapper> */}
          </PrivateRouteWrapper>
        ),
      },
      {
        path: 'chat-history',
        element: (
          <PrivateRouteWrapper>
            <SubscriptionRouteWrapper
              availableAtSubscription={[ 'premium', 'trial', 'basic' ]}>
              <ChatHistory />
            </SubscriptionRouteWrapper>
          </PrivateRouteWrapper>
        ),
      },
      {
        path: 'chat-history/:sessionId',
        element: (
          <PrivateRouteWrapper>
            <SubscriptionRouteWrapper
              availableAtSubscription={[ 'premium', 'trial', 'basic' ]}>
              <ChartHistoryDetails />
            </SubscriptionRouteWrapper>
          </PrivateRouteWrapper>
        ),
      },
      {
        path: 'reports',
        element: (
          <PrivateRouteWrapper>
            <SubscriptionRouteWrapper
              availableAtSubscription={[ 'premium', 'trial', 'basic' ]}>
              <Reports />
            </SubscriptionRouteWrapper>
          </PrivateRouteWrapper>
        ),
      },
      {
        path: 'settings',
        children: SettingsRoutes,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      // {
      //   path: '/law',
      //   element: <Law />,
      // },
      // { path: '*', element: <Navigate to={'.'} /> },
      { path: '*', element: <Navigate to={'/404'} /> },
    ],
  },
];

const Routes = () => {
  const element = useRoutes(routes);

  return element;
};

export default Routes;
