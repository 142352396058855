import React from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import { Typography } from 'antd';
import _ from 'lodash';
import { ShowQrank } from 'request/apis/portageurTypes';
import HintTooltip from './Tooltip';

interface PCProps {
  data: ShowQrank;
  formatVal: (val: number) => React.ReactNode;
}

const PC: React.FC<PCProps> = ({ data, formatVal }) => {
  return (
    <>
      {data?.qrank?.map((item, index) => (
        <div key={index} className={styles.pcItem}>
          <div className={styles.top}>
            <Typography.Paragraph className={styles.name} ellipsis>
              {item.name}
            </Typography.Paragraph>
            <div className={styles.score}>
              <div className={classNames(styles.li, styles.overall)}>
                <label className={styles.label}>Overall: </label>
                <span className={styles.number}>
                  {_.isNull(item.overall) ? '--' : item.overall}
                </span>
                {!location.pathname.includes('/chat-history') && (
                  <HintTooltip />
                )}
                {!!data?.show_detail && (
                  <div className={styles.borderRight}></div>
                )}
              </div>
              {!!data?.show_detail && (
                <>
                  <div
                    className={styles.li}
                    style={{ width: 150, marginLeft: 8 }}
                  >
                    <label className={styles.label}>Environmental:</label>
                    <span className={styles.number}>
                      {formatVal(item.environmental)}
                    </span>
                  </div>
                  <div className={styles.li} style={{ width: 90 }}>
                    <label className={styles.label}>Social:</label>
                    <span className={styles.number}>
                      {formatVal(item.social)}
                    </span>
                  </div>
                  <div className={styles.li} style={{ width: 130 }}>
                    <label className={styles.label}>Governance:</label>
                    <span className={styles.number}>
                      {formatVal(item.governance)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {!_.isEmpty(item?.important_metrics) && (
            <div className={styles.bottom}>
              Issues that impact financials: &nbsp;
              {item?.important_metrics?.map((metric, index) => (
                <span key={index} className={styles.topic}>
                  {metric}
                  {item?.important_metrics?.length !== 1 &&
                  index === item?.important_metrics?.length - 2
                    ? ', and'
                    : index === item?.important_metrics?.length - 1
                      ? '.'
                      : ','}
                  &nbsp;
                </span>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default PC;
