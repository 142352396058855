import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { SubscriptionTier } from 'request/apis/portageurTypes';
import { useCommon } from 'store';

export type SubscriptionRouteWrapperProps = PropsWithChildren<{
  availableAtSubscription: SubscriptionTier[];
}>;

export const SubscriptionRouteWrapper = ({
  children,
  availableAtSubscription,
}: SubscriptionRouteWrapperProps) => {
  const { checkSubscriptionAccess } = useCommon(state => state);

  return checkSubscriptionAccess(availableAtSubscription) ? (
    <>{children}</>
  ) : (
    <Navigate to="/library-chat" />
  );
};
