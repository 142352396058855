import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './index.module.css';

export type ContainerWidth = 'sm' | 'md';

export type ContainerProps = PropsWithChildren<{
  className?: string;
  width?: ContainerWidth;
}>;

const containerWidthClassMap: Record<ContainerWidth, string> = {
  sm: styles.sm,
  md: styles.md,
};

const Container = ({ children, className, width = 'md' }: ContainerProps) => {
  return (
    <div
      className={classNames(
        styles.container,
        containerWidthClassMap[width],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Container;
