import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  QueryMatch,
  TypeaheadOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $createTextNode,
  $getSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from 'lexical';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortageurApi } from 'request/apis/portageur';
import { Company } from 'request/apis/portageurTypes';
import styles from './CompanyPlugin.module.css';
import classNames from 'classnames';
import { useSearchService } from 'hooks/useSearchService';
import { Divider, Spin, Typography, Image, Grid, Button } from 'antd';
import { $createCompanyNode } from '../nodes/CompanyNode';
import { $createSectorNode } from '../nodes/SectorNode';
import { $createIndustryNode } from '../nodes/IndustryNode';
import {
  AppstoreFilled,
  CloseCircleFilled,
  FolderFilled,
} from '@ant-design/icons';
import { PlaceholderNode, PlaceholderType } from '../nodes/PlaceholderNode';
import { useClickAway } from 'ahooks';

const { useBreakpoint } = Grid;

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = [ '@' ].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$',
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$',
);

function checkForAtSignMentions(
  text: string,
  minMatchLength: number,
): QueryMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): QueryMatch | null {
  const match = checkForAtSignMentions(text, 0);
  return match;
}

type OptionType = 'company' | 'sector' | 'industry';

type CompanyFields<T extends OptionType> = T extends 'company' ? string : null;
class Option<T extends OptionType> extends TypeaheadOption {
  name: string;
  type: T;
  code: string;
  sector: CompanyFields<T>;
  industry: CompanyFields<T>;
  image: CompanyFields<T>;

  constructor({
    name,
    type,
    code,
    sector,
    industry,
    image,
  }: {
    name: string;
    type: T;
    code: string;
    sector: CompanyFields<T>;
    industry: CompanyFields<T>;
    image: CompanyFields<T>;
  }) {
    super(name);
    this.name = name;
    this.type = type;
    this.code = code;
    this.sector = sector;
    this.industry = industry;
    this.image = image;
  }
}

class ShowHideOption extends TypeaheadOption {
  show: boolean;
  label: string;
  type: OptionType;

  constructor(show: boolean, type: OptionType) {
    const label = show ? 'Hide' : 'View More';
    super(label);
    this.show = show;
    this.label = label;
    this.type = type;
  }
}

function Badge({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.badge}>
      <Typography.Text
        style={{ fontSize: 10, display: 'block' }}
        type="secondary"
      >
        {children}
      </Typography.Text>
    </div>
  );
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: Option<OptionType> | ShowHideOption;
}) {
  if (option instanceof ShowHideOption) {
    return (
      <li
        key={option.key}
        tabIndex={-1}
        className={classNames(isSelected && styles.selected, styles.item)}
        ref={option.setRefElement}
        role="option"
        aria-selected={isSelected}
        id={'typeahead-item-' + index}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
      >
        <Typography.Text
          underline
          style={{ color: '#ff9c32' }}
          className={styles.text}
        >
          {option.label}
        </Typography.Text>
      </li>
    );
  }
  let className = styles.item;
  if (isSelected) {
    className = classNames(styles.selected, className);
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={classNames(
        isSelected && styles.selected,
        styles.item,
        option.type === 'company' && styles.companyOption,
      )}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div className={styles.optionText}>
        {option.type === 'company' && (
          <Image
            src={option.image || undefined}
            preview={false}
            style={{ width: 24 }}
          />
        )}
        {option.type === 'sector' && (
          <FolderFilled style={{ fontSize: 16, color: '#808194' }} />
        )}
        {option.type === 'industry' && (
          <AppstoreFilled style={{ fontSize: 16, color: '#808194' }} />
        )}
        <Typography.Text className={styles.text}>{option.name}</Typography.Text>
      </div>
      {/* <div className={styles.optionBadges}>
        {option.type === "company" && (
          <>
            {option.sector && <Badge>{option.sector}</Badge>}
            {option.industry && <Badge>{option.industry}</Badge>}
          </>
        )}
      </div> */}
    </li>
  );
}

const portageurApi = new PortageurApi();

async function companyFetcher(query: string) {
  const res = await portageurApi.searchCompanies({
    keyword: query,
  });

  return res.data;
}

// async function sectorFetcher(query: string) {
//   const res = await portageurApi.searchSectors({
//     keyword: query,
//   });
//   return res.data;
// }

// async function industryFetcher(query: string) {
//   const res = await portageurApi.searchIndustries({
//     keyword: query,
//   });
//   return res.data;
// }

function getCreateNodeFn(type: OptionType) {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'company':
      return $createCompanyNode;
    case 'sector':
      return $createSectorNode;
    case 'industry':
      return $createIndustryNode;
  }
}

function Label({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.label}>
      <Typography.Text style={{ fontSize: 12 }} type="secondary">
        {children}
      </Typography.Text>
    </div>
  );
}

export type SearchType = PlaceholderType | 'all';

export const companyMenuId = 'type-company-menu';

export function CompanyPlugin(): JSX.Element | null {
  const [ editor ] = useLexicalComposerContext();

  const screens = useBreakpoint();

  const menuRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [ searchType, setSearchType ] = useState<SearchType>('all' as SearchType);
  const currentPlaceholderId = React.useRef<string | null>(null);

  const [ queryString, setQueryString ] = useState<string | null>(null);

  const [ showMoreSectors, setShowMoreSectors ] = useState(false);
  const [ showMoreIndustries, setShowMoreIndustries ] = useState(false);

  const isOpened = React.useRef(false);

  // const sectorsQuery = useSearchService({
  //   query: queryString,
  //   fetcher: sectorFetcher,
  //   initialData: [] as Sector[],
  // });

  // const industriesQuery = useSearchService({
  //   query: queryString,
  //   fetcher: industryFetcher,
  //   initialData: [] as Industry[],
  // });

  const closeMenu = useCallback(
    e => {
      let isInSuggestion = false;
      const suggestionDomList = document.querySelectorAll('.suggestion');

      suggestionDomList.forEach(suggestionDom => {
        if (suggestionDom.contains(e.target)) {
          isInSuggestion = true;
        }
      });

      if (!isOpened.current || isInSuggestion) {
        return;
      }

      editor.update(() => {
        const selection = $getSelection()?.getNodes();

        const findTextNode = selection?.find(
          node => node instanceof TextNode,
        ) as TextNode | undefined;

        if (!findTextNode) {
          return;
        }
        findTextNode.setTextContent(
          findTextNode.__text.replace(AtSignMentionsRegex, ''),
        );
      });
    },
    [ editor ],
  );

  useClickAway(closeMenu, menuRef);

  const companiesQuery = useSearchService({
    query: queryString,
    fetcher: companyFetcher,
    initialData: [] as Company[],
  });

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const { options, sectorsOptions, industriesOptions, companiesOptions } =
    useMemo(() => {
      const sectorsOptions: (Option<'sector'> | ShowHideOption)[] = [];
      const industriesOptions: (Option<'industry'> | ShowHideOption)[] = [];
      let companiesOptions: Option<'company'>[] = [];
      // if (sectorsQuery.data && ["all", "sector"].includes(searchType)) {
      //   sectorsOptions = sectorsQuery.data
      //     .slice(0, showMoreSectors ? sectorsQuery.data.length - 1 : 5)
      //     .map((sector) => {
      //       return new Option({
      //         name: sector.name,
      //         code: sector.id,
      //         type: "sector",
      //         sector: null,
      //         industry: null,
      //         image: null,
      //       });
      //     });

      //   if (sectorsQuery.data.length > 5) {
      //     const showMoreOption = new ShowHideOption(showMoreSectors, "sector");
      //     sectorsOptions.push(showMoreOption);
      //   }
      // }
      // if (industriesQuery.data && ["all", "industry"].includes(searchType)) {
      //   industriesOptions = industriesQuery.data
      //     .slice(0, showMoreIndustries ? industriesQuery.data.length - 1 : 5)
      //     .map((industry) => {
      //       return new Option({
      //         name: industry.name,
      //         code: industry.id,
      //         type: "industry",
      //         sector: null,
      //         industry: null,
      //         image: null,
      //       });
      //     });

      //   if (industriesQuery.data.length > 5) {
      //     const showMoreOption = new ShowHideOption(
      //       showMoreIndustries,
      //       "industry"
      //     );
      //     industriesOptions.push(showMoreOption);
      //   }
      // }
      if (companiesQuery.data && [ 'all', 'company' ].includes(searchType)) {
        companiesOptions = companiesQuery.data.map(company => {
          return new Option({
            name: company.name,
            code: company.code,
            type: 'company',
            sector: company.sector,
            industry: company.industry,
            image: company.logo,
          });
        });
      }

      const options: (Option<OptionType> | ShowHideOption)[] = [
        ...sectorsOptions,
        ...industriesOptions,
        ...companiesOptions,
      ];

      return { options, sectorsOptions, industriesOptions, companiesOptions };
    }, [
      companiesQuery.data,
      // sectorsQuery.data,
      // industriesQuery.data,
      showMoreIndustries,
      showMoreSectors,
      searchType,
    ]);

  const onSelectOption = useCallback(
    (
      selectedOption: Option<OptionType> | ShowHideOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      // If it's a show/hide option, we just toggle the show state
      if (selectedOption instanceof ShowHideOption) {
        switch (selectedOption.type) {
          case 'sector':
            setShowMoreSectors(!selectedOption.show);
            break;
          case 'industry':
            setShowMoreIndustries(!selectedOption.show);
            break;
        }
        return;
      }

      if (!nodeToReplace) {
        return;
      }

      editor.update(() => {
        const createNode = getCreateNodeFn(selectedOption.type);

        const nodeMap = editor.getEditorState()._nodeMap;
        // Look for the placeholder node that we're replacing
        const placeHolderNode = nodeToReplace.__parent
          ? nodeMap.get(nodeToReplace.__parent)
          : null;

        if (currentPlaceholderId.current && placeHolderNode) {
          const nodeMapEntries = nodeMap.entries();

          for (const [ _, node ] of nodeMapEntries) {
            // If the node is a placeholder node and it's id matches the current placeholder id, replace it
            if (
              node instanceof PlaceholderNode &&
              node.__id === currentPlaceholderId.current
            ) {
              const mentionNode = createNode(
                selectedOption.name,
                selectedOption.code,
              );
              const children = node.getChildren();
              node.replace(mentionNode);
              children.forEach(node => {
                node.remove();
              });
              const space = $createTextNode(' ');
              mentionNode.insertAfter(space);
              space.select();
            }
          }
        } else {
          // Otherwise, just replace the text node with the mention node
          const mentionNode = createNode(
            selectedOption.name,
            selectedOption.code,
          );
          nodeToReplace.replace(mentionNode);
          const space = $createTextNode(' ');
          mentionNode.insertAfter(space);
          space.select();
        }

        closeMenu();
      });
    },
    [ editor ],
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const mentionMatch = getPossibleQueryMatch(text);
      const slashMatch = checkForSlashTriggerMatch(text, editor);

      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [ checkForSlashTriggerMatch, editor ],
  );

  const updatePluginPosition = () => {
    if (!screens.lg) {
      return;
    }
    const rootElement = editor.getRootElement();

    if (!rootElement || !menuRef.current) {
      return;
    }
    if (contentRef.current && !screens.lg) {
      contentRef.current.scrollTop = 0;
    }

    // Make sure the menu is positioned correctly
    // Make company menu positioned according to the position of enter point
    const rootBox = rootElement?.getBoundingClientRect();
    let menuBox = menuRef.current?.getBoundingClientRect();

    // menuRef.current.style.transform = `translateY(calc(-100% - ${
    //   rootBox.height - 16
    // }px))`;

    menuBox = menuRef.current?.getBoundingClientRect();

    if (rootBox.width < menuBox?.width) {
      menuRef.current.style.maxWidth = `${rootBox?.width}px`;
      menuBox = menuRef.current?.getBoundingClientRect();
    }

    const differenceRight = menuBox?.right - rootBox?.right;

    // If the menu is overflowing on the right, move it to the left
    if (differenceRight && differenceRight > 1) {
      menuRef.current.style.transform = `translateY(calc(-100% - 16px)) translateX(-${differenceRight}px)`;
      // menuRef.current.style.transform = `translateY(calc(-100% - ${
      //         rootBox.height - 16
      //       }px)) translateX(-${differenceRight}px)`;
    }

    // If the menu is overflowing on the left, move it to the right
    const differenceLeft = rootBox?.left - menuBox?.left;
    if (differenceLeft && differenceLeft > 1) {
      menuRef.current.style.transform = `translateY(calc(-100% - 16px)) translateX(-${differenceLeft}px)`;
    }
  };

  React.useEffect(() => {
    // Check for selection changes
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection()?.getNodes();
        const nodeMap = editor.getEditorState()._nodeMap;
        // Check if the parent of the selection is a placeholder node
        const nodeToReplace = selection?.find(
          node =>
            node.__parent &&
            nodeMap.get(node.__parent) instanceof PlaceholderNode,
        ) as PlaceholderNode | undefined;

        // If it is, we need to update the text node with text that triggers the search
        // Set the search type to the placeholder type, so it only searches for that type
        if (nodeToReplace) {
          const placeholderNode = nodeMap.get(
            nodeToReplace.__parent!,
          ) as PlaceholderNode;
          currentPlaceholderId.current = placeholderNode.__id;
          setSearchType(placeholderNode.__placeholderType);
          if (!nodeToReplace.__text.includes('@')) {
            const textNode = new TextNode('@');
            nodeToReplace.replace(textNode);
            textNode.select();
          }
        }
        return true;
      },
      0,
    );
  }, []);

  return (
    <LexicalTypeaheadMenuPlugin<Option<OptionType> | ShowHideOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      onOpen={() => {
        isOpened.current = true;
      }}
      onClose={() => {
        // Reset the search type and placeholder id
        setShowMoreSectors(false);
        setShowMoreIndustries(false);
        setSearchType('all');
        currentPlaceholderId.current = null;
        isOpened.current = false;
      }}
      anchorClassName={styles.anchor}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) => {
        updatePluginPosition();
        return anchorElementRef.current
          ? ReactDOM.createPortal(
            <div
              ref={menuRef}
              id={companyMenuId}
              className={classNames(styles.menu)}
            >
              <Button
                className={classNames(styles.closeButton)}
                shape="circle"
                icon={<CloseCircleFilled />}
                onClick={closeMenu}
              />
              <div ref={contentRef} className={styles.content}>
                {/* {["all", "sector"].includes(searchType) && (
                    <>
                      <Label>Sectors</Label>
                      <ul>
                        {sectorsQuery.status === "pending" && <Spin />}
                        {sectorsOptions.map((option, i: number) => (
                          <React.Fragment key={option.key}>
                            <MentionsTypeaheadMenuItem
                              index={i}
                              isSelected={selectedIndex === i}
                              onClick={() => {
                                setHighlightedIndex(i);
                                selectOptionAndCleanUp(option);
                              }}
                              onMouseEnter={() => {
                                setHighlightedIndex(i);
                              }}
                              option={option}
                            />
                          </React.Fragment>
                        ))}
                      </ul>
                      <Divider style={{ margin: 12 }} />
                    </>
                  )}

                  {["all", "industry"].includes(searchType) && (
                    <>
                      <Label>Industries</Label>
                      <ul>
                        {industriesQuery.status === "pending" && <Spin />}
                        {industriesOptions.map((option, i) => {
                          const computedIndex = i + sectorsOptions.length;
                          return (
                            <MentionsTypeaheadMenuItem
                              index={computedIndex}
                              isSelected={selectedIndex === computedIndex}
                              onClick={() => {
                                setHighlightedIndex(computedIndex);
                                selectOptionAndCleanUp(option);
                              }}
                              onMouseEnter={() => {
                                setHighlightedIndex(computedIndex);
                              }}
                              key={option.key}
                              option={option}
                            />
                          );
                        })}
                      </ul>
                      <Divider style={{ margin: 12 }} />
                    </>
                  )} */}

                {[ 'all', 'company' ].includes(searchType) && (
                  <>
                    {/* <Label>Companies</Label> */}
                    <ul>
                      {companiesQuery.status === 'pending' && <Spin />}
                      {companiesQuery.data?.length === 0 && (
                        <Typography.Text>No results found</Typography.Text>
                      )}
                      {companiesOptions.map((option, index) => {
                        const computedIndex =
                            index +
                            sectorsOptions.length +
                            industriesOptions.length;
                        return (
                          <MentionsTypeaheadMenuItem
                            index={computedIndex}
                            isSelected={selectedIndex === computedIndex}
                            onClick={() => {
                              setHighlightedIndex(computedIndex);
                              selectOptionAndCleanUp(option);
                            }}
                            onMouseEnter={() => {
                              setHighlightedIndex(computedIndex);
                            }}
                            key={option.key}
                            option={option}
                          />
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </div>,
            anchorElementRef.current,
          )
          : null;
      }}
    />
  );
}
