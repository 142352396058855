import { Image } from 'antd';
import React, { FC } from 'react';
import styles from './index.module.css';
import { importGif } from 'utils';
import ismobilejs from 'ismobilejs';
import treeMini from './treeMini';

interface Props {
  style?: React.CSSProperties;
  width?: number;
}

const Loading: FC<Props> = ({ style, width }) => {
  const isMobile = ismobilejs(window.navigator).any;

  return (
    <div className={styles.loading} style={style}>
      <Image
        src={isMobile ? treeMini : importGif('tree')}
        preview={false}
        className={styles.content}
        style={{ width }}
      />
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
