import type { SerializedLexicalNode, Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';

export type SerializedMetricNode = Spread<
{
  metricName: string;
  type: 'metric';
  version: 1;
  code: string;
},
SerializedTextNode
>;

function convertMetricElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMetricNode(textContent, '');
    return {
      node,
    };
  }

  return null;
}

const MetricStyle = 'color: #ff9c32';
export class MetricNode extends TextNode {
  __metric: string;
  __code: string;

  static getType(): string {
    return 'metric';
  }

  static isSerializedNode(
    node: SerializedLexicalNode,
  ): node is SerializedMetricNode {
    return node.type === this.getType();
  }

  static clone(node: MetricNode): MetricNode {
    return new MetricNode(node.__metric, node.__code, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedMetricNode): MetricNode {
    const node = $createMetricNode(
      serializedNode.metricName,
      serializedNode.code,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(metricName: string, code: string, text?: string, key?: NodeKey) {
    super(text ?? metricName, key);
    this.__metric = metricName;
    this.__code = code;
  }

  exportJSON(): SerializedMetricNode {
    return {
      ...super.exportJSON(),
      metricName: this.__metric,
      type: 'metric',
      version: 1,
      code: this.__code,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = MetricStyle;
    dom.className = 'metric';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-Metric', 'true');
    element.textContent = this.__text;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-Metric')) {
          return null;
        }
        return {
          conversion: convertMetricElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createMetricNode(
  metricName: string,
  code: string,
): MetricNode {
  const metricNode = new MetricNode(metricName, code);
  metricNode.setMode('segmented').toggleDirectionless();
  return metricNode;
}

export function $isMetricNode(
  node: LexicalNode | null | undefined,
): node is MetricNode {
  return node instanceof MetricNode;
}
