import { envVars } from 'utils/envVars';
const parsedUrl = new URL(envVars.audience);

export default {
  getToken: {
    method: 'post',
    url: '/user/token/v1',
  },
  getUserinfo: {
    url: `${parsedUrl.origin}/userinfo`,
  },
  createIndent: {
    method: 'post',
    url: '/premium/create_indent/v1',
  },
};
