import _ from 'lodash';
import { message } from 'antd';

export const debMsg = _.debounce((msg, type = 'error') => {
  message.destroy();
  message[type](msg);
}, 600);

const hideUrlList = [ '/investment/question/v1', '/chat/question/v2' ];

const errorMessages = {
  trialEnded: 'trial has expired',
  queryLimit: 'user has reached the limit',
};

export const interceptor = async response => {
  const { data, config } = response;
  const res = data;

  if (/400|500|404|403/.test(res.code)) {
    if (!hideUrlList.includes(config.url)) {
      debMsg(res.msg, 'warn');
    }
    return Promise.reject(res);
  }

  if (res.code === 402 && res.msg === errorMessages.trialEnded) {
    return Promise.reject(res);
  }

  if (res.code === 402 && res.msg !== errorMessages.trialEnded) {
    window.location.replace('/library-chat');
    debMsg(res.msg, 'warn');
    return Promise.reject(res);
  }

  if (res.code !== 200) {
    // res.code !== 401 && debMsg(res.msg, 'warn');
    return Promise.reject(res);
  }

  return response;
};

const MSG_MAP = {
  ERR_NETWORK: 'Network error, please refresh page',
  ERR_BAD_RESPONSE: 'Network error, please refresh page',
  ECONNABORTED: 'Something went wrong. Please try again.',
};

export const handerError = async error => {
  const { status } = error?.response ?? {};
  console.error('ERROR ERROR ERROR API ====> ', error);

  if (
    [ 'ERR_BAD_RESPONSE', 'ERR_NETWORK', 'ECONNABORTED' ].includes(error.code)
  ) {
    if (!hideUrlList.includes(error.config.url)) {
      error.message = MSG_MAP[error.code] || MSG_MAP.ERR_NETWORK;
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        code: -201,
        msg: 'Network error. Try again.',
      });
    }
  }

  if (status === 402) {
    window.location.replace('/library-chat');
    return Promise.reject(error);
  }

  if (status === 401) {
    // redirect to login
    localStorage.clear();
    window?.logout?.();
    window?.loginWithRedirect?.();
    return Promise.resolve();
  }

  debMsg(error?.message || JSON.stringify(error));
  return Promise.reject(error);
};
