import { create } from 'zustand';
import { MenuLinks, User } from 'config/interface';
import { menuLinks } from 'config/menu';
import { PortageurApi } from 'request/apis/portageur';
import { SubscriptionTier } from 'request/apis/portageurTypes';
interface CommonStore {
  pageLoading: boolean;
  menu: MenuLinks;
  setPageLoading: (v: boolean) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (v: boolean) => void;
  getUser: () => Promise<void>;
  user: User;
  subscription: User['subscription'];
  checkSubscriptionAccess: (allowedSubs: SubscriptionTier[]) => boolean;
}

const portageurApi = new PortageurApi();

const subscription = {
  tier: '' as SubscriptionTier,
  expiresAt: '',
  isSubscribed: false,
  daysLeft: 0,
};

export const useCommon = create<CommonStore>((set, get) => ({
  menu: menuLinks,
  pageLoading: true,
  user: {
    subscription,
  } as User,
  subscription,
  setPageLoading: pageLoading => {
    set(() => ({
      pageLoading,
    }));
  },
  isAuthenticated: !!localStorage.getItem('iamToken'),
  setIsAuthenticated: isAuthenticated => {
    set(() => ({
      isAuthenticated,
    }));
  },
  checkSubscriptionAccess: allowedSubs => {
    const { user } = get();
    if (!user) return false;
    if (!user.subscription) return false;
    return (
      user.subscription.isSubscribed &&
      allowedSubs.includes(user.subscription.tier)
    );
  },

  getUser: async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return;
    try {
      const user = await portageurApi.getUser().then(res => res.data);
      const subscription = {
        tier: user.subscription_tier as SubscriptionTier,
        expiresAt: new Date(user.subscription_expired_at).toString(),
        isSubscribed: Boolean(user.subscription_status),
        daysLeft: user.subscription_days_left as number,
      };
      localStorage.setItem('iam_uid', `${user.iam_uid}`);
      set(() => ({
        user: { ...user, subscription, newUser: Boolean(user.new_user) } as unknown as User,
        subscription,
      }));
    } finally {
      set(() => ({
        pageLoading: false,
      }));
    }

  },
}));
