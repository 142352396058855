export * from './util';
export const getHeader = () => {
  const headers = {};
  const iamToken = localStorage.getItem('iamToken') || '';
  iamToken && (headers.Authorization = 'Bearer ' + iamToken);
  return headers;
};

export const parseBlob = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);

    reader.onload = function() {
      const result = reader.result;
      try {
        resolve(JSON.parse(result));
      } catch (error) {
        resolve({ result });
      }
    };
    reader.onerror = function(e) {
      reject(e);
    };
  });
};

export const getType = data => {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
};

export const isType = (data, type) => {
  return type === getType(data);
};
