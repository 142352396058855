import styles from './index.module.css';
import { importImg } from 'utils';
import { Typography, Image } from 'antd';
import classNames from 'classnames';
import React from 'react';

export type UsageInfoProps = {
  className?: string;
};

const UsageInfo: React.FC<UsageInfoProps> = ({ className }) => {
  return (
    <div className={className}>
      <div className={classNames(styles.textWrapper)}>
        <Typography.Text>
          You're nearing the usage limit.
        </Typography.Text>
        <Image
          src={importImg('alert')}
          className={styles.alertIcon}
          style={{ width: 20, height: 20 }}
          preview={false}
        />
      </div>
    </div>
  );
};

export default UsageInfo;
