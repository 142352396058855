import React from 'react';
import { ShowQrank } from 'request/apis/portageurTypes';
import styles from './index.module.css';
import { Collapse, Typography } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import IconFont from 'components/IconFont';

const { Panel } = Collapse;

interface MobileProps {
  data: ShowQrank;
  formatVal: (val: number) => React.ReactNode;
}

const Mobile: React.FC<MobileProps> = ({ data, formatVal }) => {
  return (
    <div className={styles.mobile}>
      {data?.qrank?.map((item, index) => (
        <Collapse
          ghost
          key={index}
          className={styles.collapse}
          defaultActiveKey={index === 0 ? [ '1' ] : []}
          expandIconPosition={'end'}
          expandIcon={({ isActive }) => {
            return (
              <IconFont
                type="arrows"
                style={{
                  fontSize: 24,
                  color: isActive ? 'red' : '#000',
                  transform: `translateY(-50%) rotate(${
                    isActive ? 0 : 180
                  }deg)`,
                }}
              />
            );
          }}>
          <Panel
            header={
              <Typography.Paragraph className={styles.name} ellipsis>
                {item.name}
              </Typography.Paragraph>
            }
            key="1">
            <div className={styles.esg}>
              <div className={classNames(styles.li, styles.overall)}>
                <label className={styles.label}>Overall:</label>
                <span className={styles.number}>
                  {_.isNull(item.overall) ? '--' : item.overall}
                </span>
              </div>
              {!!data?.show_detail && (
                <>
                  <div className={styles.li}>
                    <label className={styles.label}>Environmental:</label>
                    <span className={styles.number}>
                      {formatVal(item.environmental)}
                    </span>
                  </div>
                  <div className={styles.li}>
                    <label className={styles.label}>Social:</label>
                    <span className={styles.number}>
                      {formatVal(item.social)}
                    </span>
                  </div>
                  <div className={styles.li}>
                    <label className={styles.label}>Governance:</label>
                    <span className={styles.number}>
                      {formatVal(item.governance)}
                    </span>
                  </div>
                </>
              )}
            </div>
            {!_.isEmpty(item?.important_metrics) && (
              <div className={styles.metrics}>
                  Issues that impact financials:&nbsp;
                {item?.important_metrics?.map((metric, index) => (
                  <span key={index} className={styles.topic}>
                    {metric}
                    {item?.important_metrics?.length !== 1 &&
                        index === item?.important_metrics?.length - 2
                      ? ', and'
                      : index === item?.important_metrics?.length - 1
                        ? '.'
                        : ','}
                        &nbsp;
                  </span>
                ))}
              </div>
            )}
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};

export default Mobile;
