const gTag = {
  currentUrl: '',
  currentTitle: '',
};

window.dataLayer = window.dataLayer || [];

gTag.s_uid = () => {
  return new Promise(resolve => {
    if (localStorage.getItem('iam_uid')) {
      resolve(localStorage.getItem('iam_uid'));
    } else {
      const interval = setInterval(() => {
        if (localStorage.getItem('iam_uid')) {
          resolve(localStorage.getItem('iam_uid'));
          clearInterval(interval);
        }
      }, 200);
    }
  });
};
gTag.push = function() {
  // console.log("gTag send ",arguments );
  window.dataLayer.push(arguments);
};
gTag.send = async (_event, param = {}) => {
  const s_uid = await gTag.s_uid();

  if (s_uid !== '') {
    param = { ...param, s_uid };
  }

  gTag.push('event', _event, param);
};
gTag.pageView = pathValue => {
  if (
    gTag.currentUrl !== location.href ||
    gTag.currentTitle !== document.title
  ) {
    gTag.currentUrl = location.href;
    gTag.currentTitle = document.title;
    gTag.send('pageview', {
      page_title: document.title,
      page_location: location.href,
      page_path: pathValue || window.location.pathname,
    });
  }
};

// gTag.scroll = (percent_scrolled, item, page_path) => {
//     gTag.send("scroll", {
//         percent_scrolled,
//         item: "page" || item,
//         page_path: page_path || window.location.pathname });
// };

gTag.copy = () => {
  gTag.send('copy');
};

gTag.viewHistory = () => {
  gTag.send('view_history');
};

gTag.clickPlan = (type, time) => {
  gTag.send('click_plan', { type, time });
};

gTag.clickContact = position => {
  gTag.send('click_contact', { position });
};

gTag.switchPlan = time => {
  gTag.send('switch_plan', { time });
};

gTag.clickButton = buttonName => {
  gTag.send('click_button', { buttonName });
};

export default gTag;
