import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import IconFont from 'components/IconFont';
import { TextElement } from 'request/apis/portageurTypes';
import { useDebounceFn } from 'ahooks';
import styles from './index.module.css';

interface BottomButProps {
  right: number|string;
  messageRef: React.RefObject<HTMLDivElement>;
  openReference: TextElement;
  scrollToBottom: (v?: number) => void;
}

interface RefProps {
  autoScrollBottom: (flag: boolean) => void;
}

const BottomBut = React.forwardRef<RefProps, BottomButProps>(({
  right,
  messageRef,
  openReference,
  scrollToBottom,
}, ref) => {
  const targetRef = useRef<any>();
  const scrollRef = useRef<number>(0);
  const [ isScrolling, setIsScrolling ] = useState(false);
  const [ isVisible, setIsVisible ] = useState(false);

  const handleScroll = () => {
    let timeout;
    setIsScrolling(true);
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 150);
  };

  const getIsVisible = (distance = 130) => {
    if (targetRef.current) {
      const scrollPosition = targetRef.current.scrollTop;
      const height = targetRef.current.offsetHeight;
      const contentHeight = (
        (openReference
          ? messageRef.current
          : document.querySelector('.chat-container')) as HTMLDivElement
      )?.offsetHeight;

      scrollRef.current = scrollPosition;

      return scrollPosition + height < contentHeight - distance;
    }

    return false;
  };

  const getIsAutoScrollBottom = () => {
    if (isScrolling) return false;
    return !getIsVisible();
  };

  const autoScrollBottom = flag => {
    if (flag) {
      scrollToBottom();
    } else {
      getIsAutoScrollBottom() && scrollToBottom();
    }
  };

  useImperativeHandle(ref, () => ({
    autoScrollBottom,
  }), [ isScrolling ]);

  const toggleVisibility = () => {
    if (targetRef.current) {
      const isVisible = getIsVisible();
      setIsVisible(isVisible);
    }
  };

  const { run } = useDebounceFn(toggleVisibility, { wait: 600 });

  useEffect(() => {
    run();
  });

  const onScroll = () => {
    run();
    handleScroll();
  };

  useEffect(() => {
    targetRef.current = document.querySelector(
      openReference ? '.chat-container-msg' : '.ant-layout-content',
    );

    if (openReference) {
      run();
    } else {
      scrollRef.current && scrollToBottom(scrollRef.current);
    }

    targetRef.current?.addEventListener?.('scroll', onScroll);

    return () => {
      targetRef.current?.removeEventListener?.('scroll', onScroll);
    };
  }, [ openReference ]);

  return (
    isVisible && (
      <div
        className={styles.bottomBut}
        onClick={() => scrollToBottom()}
        style={openReference ? { right } : {}}>
        <IconFont type="arrown-bottom" style={{ fontSize: 14 }}></IconFont>
      </div>
    )
  );
});

export default BottomBut;
